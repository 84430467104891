<template>
    <div class="bg-white">
        <!-- Header - Smart Sticky (hides on scroll down, shows on scroll up) -->
        <div ref="header" class="fixed top-0 left-0 right-0 bg-white z-[30] shadow-sm transition-transform duration-300"
            :class="{ 'translate-y-0': showHeader, '-translate-y-full': !showHeader }">
            <div class="container mx-auto px-4 py-3">
                <div class="flex items-center justify-between">
                    <div class="flex items-center gap-2">
                        <LogoSmall @click="goHome" class="cursor-pointer" />
                        <div @click="burger = false">
                            <RegionClinic />
                        </div>
                    </div>

                    <!-- Action Buttons -->
                    <div class="flex items-center gap-2">
                        <!-- Phone Button -->
                        <UIMessengersBlock :showLabel="false" :mobile="true" />

                        <!-- Menu Button -->
                        <button class="w-6 h-6 flex flex-col items-center justify-center gap-1 relative"
                            @click="toggleBurger" aria-label="Toggle menu" tabindex="0" @keydown.enter="toggleBurger"
                            @keydown.space="toggleBurger">
                            <!-- Burger Icon -->
                            <div class="transition-all duration-300 ease-in-out absolute"
                                :class="burger ? 'opacity-0 scale-0' : 'opacity-100 scale-100'">
                                <div class="w-5 h-[1.5px] bg-[#2d2a34] rounded-sm mb-1"></div>
                                <div class="w-5 h-[1.5px] bg-[#2d2a34] rounded-sm mb-1"></div>
                                <div class="w-3 h-[1.5px] bg-[#2d2a34] rounded-sm"></div>
                            </div>
                            <!-- Close Icon -->
                            <div class="w-6 h-6 relative flex items-center justify-center transition-all duration-300 ease-in-out absolute"
                                :class="burger ? 'opacity-100 scale-100' : 'opacity-0 scale-0'">
                                <div class="absolute w-[22px] h-[1.5px] bg-[#2d2a34] rounded-sm transform rotate-45">
                                </div>
                                <div class="absolute w-[22px] h-[1.5px] bg-[#2d2a34] rounded-sm transform -rotate-45">
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Spacer to prevent content from being hidden under the fixed header -->
        <div class="h-[68px]"></div>

        <!-- Mobile Menu Overlay -->
        <transition name="menu-overlay">
            <div v-if="burger" class="fixed inset-x-0 top-[68px] bottom-0 bg-white z-[999999] overflow-y-auto">
                <div class="container mx-auto px-6 py-6">
                    <nav class="flex flex-col gap-2 mb-4">
                        <div v-for="(item, index) in menuItems" :key="index" class="flex flex-col">
                            <!-- Services item with dropdown -->
                            <div v-if="item.title === 'Услуги'" class="flex flex-col">
                                <div @click="toggleServicesDropdown" class="text-[#2d2a34] text-2xl cursor-pointer">
                                    <div class="flex items-center justify-between">
                                        {{ item.title }}
                                        <ChevronDownIcon
                                            class="w-6 h-6 text-[#2d2a34] transition-transform duration-200"
                                            :class="{ 'rotate-180': showServicesDropdown }" />
                                    </div>
                                </div>

                                <!-- Services Dropdown -->
                                <div v-if="showServicesDropdown" class="mt-2 font-light opacity-75 flex flex-col gap-3">
                                    <NuxtLink v-for="(service, serviceIndex) in subMenuNav" :key="serviceIndex"
                                        :to="`/services/${service.url}`" class="text-[#2d2a34] text-lg cursor-pointer"
                                        @click="toggleBurger(); closeApp();">
                                        {{ service.title }}
                                    </NuxtLink>

                                    <NuxtLink to="/services" class="w-full" @click="toggleBurger">
                                        <UIBtnWhite text="Все услуги" size="sm" class="w-full mb-2" />
                                    </NuxtLink>
                                </div>
                                <div class="h-px bg-[#f4f6f9] mt-2"></div>
                            </div>

                            <!-- Regular menu items -->
                            <NuxtLink v-else :to="item.url" class="text-[#2d2a34] text-2xl cursor-pointer"
                                @click="toggleBurger(); closeApp()">
                                <div class="flex items-center justify-between">
                                    {{ item.title }}
                                    <ChevronRightIcon v-if="item.hasSubmenu" class="w-4 h-4 text-[#2d2a34]" />
                                </div>
                                <div class="h-px bg-[#f4f6f9] mt-2"></div>
                            </NuxtLink>
                        </div>
                    </nav>

                    <UIBtnWhite :text="phone" @click="clickPhone()" class="w-full mb-2" />
                    <UIBtnPink :text="'Выезд на дом'" class="w-full mb-2" @click="homeStore.openHome(); toggleBurger()"
                        v-if="regionStore.regionInfo.attributes.home !== false" />
                    <UIBtnGreen :text="'Записаться'" class="w-full mb-2"
                        @click="appStore.openApp('all'); toggleBurger()" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import { useServices } from '~/store/services';
import { useClinics } from '~/store/clinics';
import { useHome } from '~/store/home';
import { useApp } from '~/store/app/app';
import { useRegion } from '~/store/app/region';

export default {
    name: 'HeaderMobile',
    components: {
        ChevronDownIcon,
        ChevronRightIcon
    },
    setup() {
        const homeStore = useHome();
        const appStore = useApp();
        const regionStore = useRegion();

        return {
            homeStore,
            appStore,
            regionStore
        }
    },
    data() {
        return {
            burger: false,
            showHeader: true,
            lastScrollPosition: 0,
            scrollThreshold: 5,
            showServicesDropdown: false,
            scrollPosition: 0,
            menuItems: [
                { title: 'Услуги', url: '/services', hasSubmenu: true },
                { title: 'Врачи', url: '/docs', hasSubmenu: false },
                { title: 'Цены', url: '/price', hasSubmenu: false },
                { title: 'Вакцинация', url: '/vacc', hasSubmenu: false },
                { title: 'Программы', url: '/programs', hasSubmenu: false },
                { title: 'Чекапы', url: '/services/checkup', hasSubmenu: false },
                { title: 'Клиники', url: '/contacts', hasSubmenu: false },
                { title: 'Блог', url: '/blog', hasSubmenu: false },
                { title: 'О нас', url: '/about', hasSubmenu: false },
            ]
        }
    },
    computed: {
        phone() {
            return this.regionStore?.regionInfo?.attributes?.phone ? this.regionStore.regionInfo.attributes.phone : '+7 495 812-98-21';
        },
        subMenuNav() {
            try {
                const servicesStore = useServices();

                return servicesStore.listServices
                    .filter((service) => {
                        try {
                            const serviceEx = servicesStore.getServiceById(+service.id);
                            return (
                                service.attributes.menu &&
                                serviceEx?.attributes?.clinics?.data &&
                                serviceEx?.attributes?.clinics?.data?.findIndex(
                                    (clinic) =>
                                        this.regionStore.listClinicsRegion().includes(clinic.attributes.clinicId)
                                ) !== -1
                            );
                        } catch (e) {
                            console.error("Error filtering service:", e);
                            return false;
                        }
                    })
                    .map((service) => ({
                        title: service.attributes.title,
                        url: service.attributes.link,
                    }));
            } catch (e) {
                console.error("Error in subMenuNav computed:", e);
                return [];
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);

        // Fix for RegionModal positioning
        const appRoot = document.getElementById('app') || document.body;
        const regionModals = document.querySelectorAll('.region-modal-container');

        // Move any existing RegionModals to the app root
        if (regionModals.length > 0) {
            regionModals.forEach(modal => {
                appRoot.appendChild(modal);
            });
        }
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        closeApp() {
            this.appStore.open = false;
            this.homeStore.open = false;
        },
        clickPhone() {
            const phone = this.phone.replace(/\s/g, '');
            const phoneLink = `tel:${phone}`;
            window.open(phoneLink, '_self');
        },
        goHome() {
            this.$router.push('/');
            this.appStore.open = false;
            this.homeStore.open = false;
        },
        openRegionModal() {
            console.log('openRegionModal');

            this.burger = false;
            this.showServicesDropdown = false;
        },
        lockScroll() {
            this.scrollPosition = window.pageYOffset;
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.top = `-${this.scrollPosition}px`;
            document.body.style.width = '100%';
        },
        unlockScroll() {
            document.body.style.removeProperty('overflow');
            document.body.style.removeProperty('position');
            document.body.style.removeProperty('top');
            document.body.style.removeProperty('width');
            window.scrollTo(0, this.scrollPosition);
        },
        toggleBurger() {
            this.burger = !this.burger;
            // Always show header when menu is open
            if (this.burger) {
                this.showHeader = true;
                this.lockScroll();
            } else {
                this.unlockScroll();
            }
        },
        toggleServicesDropdown() {
            this.showServicesDropdown = !this.showServicesDropdown;
        },
        handleScroll() {
            // Don't hide header when menu is open or app open or home open
            if (this.burger || this.appStore.open || this.homeStore.open) {
                this.showHeader = true;
                return;
            }

            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

            // If we're at the top of the page, always show the header
            if (currentScrollPosition < 50) {
                this.showHeader = true;
                this.lastScrollPosition = currentScrollPosition;
                return;
            }

            // Check if we've scrolled past the threshold
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < this.scrollThreshold) {
                return;
            }

            // Show/hide based on scroll direction
            this.showHeader = currentScrollPosition < this.lastScrollPosition;
            this.lastScrollPosition = currentScrollPosition;
        }
    },
    watch: {
        'appStore.open': function (val) {
            if (val)
                this.showHeader = val;
        },
        'homeStore.open': function (val) {
            if (val)
                this.showHeader = val;
        }
    }
}
</script>

<style scoped>
.menu-overlay-enter-active,
.menu-overlay-leave-active {
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.menu-overlay-enter-from,
.menu-overlay-leave-to {
    opacity: 0;
    transform: translateY(-100%);
}

.menu-overlay-enter-to,
.menu-overlay-leave-from {
    opacity: 1;
    transform: translateY(0);
}

/* Remove dropdown animation styles */
</style>
