<template>
  <Transition enter-active-class="transition duration-300 ease-out" enter-from-class="transform opacity-0"
    enter-to-class="opacity-100" leave-active-class="transition duration-200 ease-in" leave-from-class="opacity-100"
    leave-to-class="transform opacity-0">
    <!-- Overlay with blur -->
    <div v-if="modelValue"
      class="fixed inset-0 bg-[#2D2A34]/30 backdrop-blur-sm z-40 overflow-hidden region-modal-container">
      <!-- Modal container with center positioning -->
      <div class="fixed inset-0 flex items-center justify-center z-50 p-4">
        <Transition enter-active-class="transition duration-300 ease-out delay-150"
          enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-200 ease-in" leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0">
          <!-- Modal content -->
          <div
            class="w-full max-w-[760px] min-h-[459px] relative bg-white rounded-3xl shadow-[0px_4px_16px_0px_rgba(41,43,51,0.16)] p-6 md:p-16 overflow-hidden">
            <!-- Close button -->
            <button @click="handleClose"
              class="absolute right-4 top-4 md:right-8 md:top-8 hover:opacity-80 transition-opacity"
              aria-label="Закрыть">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="#F5F7FA" />
                <path d="M11.7574 11.7574L20.2426 20.2426" stroke="#919499" stroke-width="1.5" stroke-linecap="round" />
                <path d="M20.2426 11.7574L11.7574 20.2426" stroke="#919499" stroke-width="1.5" stroke-linecap="round" />
              </svg>
            </button>

            <!-- Title -->
            <h2 class="text-3xl md:text-5xl font-light text-[#2d2a34] mt-4 md:mt-8 text-center md:text-left">
              Выберите ваш город 
            </h2>

            <!-- Description -->
            <p class="mt-4 md:mt-4 text-xl text-[#2d2a34]/50 font-light max-w-[450px] text-center md:text-left">
              От выбора зависит стоимость приёма и список доступных врачей.
            </p>

            <!-- Region buttons container -->
            <div class="mt-8 md:mt-4 space-y-4">
              <!-- Combined regions in one flex container -->
              <div class="flex flex-wrap gap-3 max-w-[400px]">
                <button v-for="region in regions" :key="region.id" @click="handleRegionSelect(region)" :class="[
                  'px-4 py-2 rounded-3xl flex items-center gap-2 text-sm md:text-base border ',
                  selectedRegion?.id === region.id
                    ? 'bg-[#72e0d8] border-[#72e0d8]'
                    : 'border-[#edeef2]'
                ]">
                  <span class="text-[#2d2a34] font-['Circe']">
                    {{ region.name }}
                  </span>
                  <span v-if="region.clinicsCount" class="w-5 h-5 rounded-full flex items-center justify-center" :class="{
                    'bg-[#edeef2]': selectedRegion?.id !== region.id,
                    'bg-[white]': selectedRegion?.id === region.id
                  }">
                    <span class="text-[#2d2a34] text-xs leading-none">
                      {{ region.clinicsCount }}
                    </span>
                  </span>
                </button>
              </div>
            </div>

            <!-- Decorative SVG - Hidden on mobile -->
            <div class="absolute right-0 md:right-[-20px] md:bottom-[-10px] bottom-0 md:right-0 md:bottom-0">
              <svg width="280" height="125" class="md:w-[382px] md:h-[171px]" viewBox="0 0 382 171" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_345_2726)">
                  <path
                    d="M294.08 125.561C309.535 131.095 324.327 138.941 338.261 147.574C353.544 157.068 369.342 168.283 379.645 183.361C380.528 184.664 382.662 183.434 381.755 182.131C373.88 170.595 362.792 161.446 351.483 153.428C339.169 144.696 325.971 137.145 312.331 130.652C306.567 127.897 300.704 125.339 294.693 123.2C293.246 122.658 292.608 125.044 294.08 125.561Z"
                    fill="#2D2A34" />
                  <path
                    d="M2.06062 183.606C12.4128 172.194 25.7823 163.512 38.882 155.567C53.0855 146.959 67.8533 139.261 83.0136 132.497C89.3672 129.668 95.7944 126.987 102.393 124.847C103.89 124.356 103.252 121.994 101.731 122.486C88.5822 126.741 75.9241 132.792 63.6094 139.064C48.0812 146.959 32.8473 155.641 18.7664 165.898C12.192 170.694 5.78937 175.834 0.318906 181.86C-0.735936 183.041 1.00578 184.787 2.06062 183.606Z"
                    fill="#2D2A34" />
                  <path
                    d="M101.829 113.042C99.3023 113.386 98.1003 116.239 99.2287 118.428C100.627 121.158 103.742 120.396 106.22 120.027C113.113 119.067 119.958 117.42 126.557 115.181C131.929 113.361 138.479 111.099 142.256 106.622C143.311 105.368 144.979 102.785 143.483 101.383C141.545 99.5632 138.013 99.391 135.559 99.4402C127.096 99.5878 118.633 102.687 110.832 105.687C109.36 106.253 109.998 108.614 111.47 108.049C116.769 106.007 122.165 104.163 127.709 102.933C132.027 101.974 138.356 100.547 142.232 103.498C142.036 103.154 141.839 102.785 141.619 102.441C141.398 105.343 137.841 107.385 135.609 108.639C131.708 110.853 127.415 112.377 123.147 113.706C118.682 115.083 114.119 116.19 109.532 117.026C107.52 117.395 105.509 117.739 103.473 117.912C102.565 117.985 101.559 118.133 101.265 117.001C101.02 116.091 101.584 115.526 102.467 115.403C104.037 115.181 103.375 112.82 101.829 113.042Z"
                    fill="#2D2A34" />
                  <path
                    d="M100.185 119.805C100.995 122.855 100.848 125.979 99.008 128.611C97.3398 130.996 94.6169 132.521 91.943 133.481C90.4711 134.022 91.1089 136.383 92.6053 135.842C95.917 134.637 99.0816 132.792 101.118 129.84C103.325 126.692 103.522 122.757 102.54 119.141C102.148 117.616 99.793 118.256 100.185 119.805Z"
                    fill="#2D2A34" />
                  <path
                    d="M141.226 103.621C144.391 109.574 149.567 115.772 157.049 114.567C161.464 113.853 165.905 113.066 170.345 112.402C184.548 110.287 198.948 110.311 213.274 110.877C223.185 111.271 233.071 111.959 242.933 113.017C244.503 113.189 244.478 110.73 242.933 110.557C226.374 108.762 209.668 107.852 193.012 108.147C182.071 108.344 171.473 109.746 160.679 111.369C158.153 111.738 155.528 112.673 153.001 112.009C148.365 110.803 145.446 106.352 143.336 102.367C142.6 100.99 140.49 102.22 141.226 103.621Z"
                    fill="#2D2A34" />
                  <path
                    d="M180.427 120.248C176.404 119.756 171.228 120.961 168.014 123.495C166.346 124.823 165.291 126.717 165.561 128.906C165.905 131.562 167.695 133.972 170.198 134.907C173.019 135.965 176.183 135.547 179.102 135.227C181.85 134.932 184.499 135.128 187.247 135.153C190.289 135.178 193.527 134.981 196.299 133.579C198.825 132.3 200.984 129.496 201.426 126.668C202.358 120.838 194.483 120.051 190.436 120.224C188.866 120.297 188.841 122.757 190.436 122.683C192.644 122.585 198.654 122.437 199.046 125.561C199.341 127.873 196.937 130.504 195.048 131.439C192.766 132.571 190.092 132.694 187.59 132.694C185.382 132.694 183.175 132.423 180.967 132.571C178.563 132.743 176.183 133.185 173.755 133.087C171.277 132.989 169.241 132.152 168.26 129.717C167.573 128.07 168.039 126.569 169.388 125.462C172.136 123.175 176.87 122.265 180.378 122.683C181.997 122.88 181.973 120.42 180.427 120.248Z"
                    fill="#2D2A34" />
                  <path
                    d="M244.601 112.771C248.379 111.344 251.568 108.836 253.383 105.171H251.273C254.855 110.311 261.65 112.919 267.39 114.689C273.376 116.559 280.343 118.108 286.623 116.952C288.561 116.608 292.338 115.796 292.854 113.411C293.295 111.271 290.719 109.992 289.149 109.278C283.851 106.868 277.89 105.442 272.346 103.744C268.347 102.515 264.25 101.359 260.055 101.064C257.014 100.842 253.555 101.113 251.347 103.474C250.267 104.63 251.985 106.376 253.089 105.22C254.83 103.351 257.7 103.375 260.055 103.548C263.073 103.769 266.066 104.531 268.985 105.343C273.916 106.721 278.797 108.344 283.679 109.893C285.911 110.607 288.095 111.369 290.082 112.623C290.793 113.066 290.499 112.992 289.738 113.435C288.585 114.099 287.113 114.444 285.813 114.64C280.441 115.501 274.553 114.222 269.402 112.746C263.98 111.197 256.842 108.836 253.432 103.966C252.941 103.277 251.764 103.08 251.322 103.966C249.801 107.065 247.226 109.229 244.012 110.434C242.466 110.951 243.104 113.312 244.601 112.771Z"
                    fill="#2D2A34" />
                  <path
                    d="M290.376 113.165C290.4 117.543 291.553 121.724 293.295 125.733C294.816 129.25 296.803 132.521 298.447 135.965C299.134 137.391 301.243 136.137 300.556 134.735C299.011 131.488 297.147 128.414 295.675 125.118C293.957 121.355 292.854 117.346 292.805 113.189C292.829 111.59 290.376 111.566 290.376 113.165Z"
                    fill="#2D2A34" />
                  <path
                    d="M110.464 151.116C114.732 145.09 119.761 139.531 126.139 135.694C129.77 133.53 134.456 131.095 138.773 131.07C140.466 131.07 141.104 131.734 140.711 133.456C140.073 136.309 137.964 138.67 135.903 140.638C131.463 144.868 126.139 147.943 121.086 151.337C118.731 152.936 116.523 155.199 113.727 155.986C109.728 157.092 110.636 151.927 112.255 150.058C113.285 148.853 111.543 147.107 110.513 148.312C108.649 150.476 107.349 154.067 109.09 156.674C111.077 159.65 114.757 158.519 117.284 157.019C119.933 155.444 122.337 153.428 124.937 151.731C128.151 149.64 131.365 147.549 134.357 145.163C137.179 142.95 140.024 140.392 141.815 137.244C142.943 135.251 144.219 131.98 142.477 129.963C141.03 128.315 138.528 128.512 136.565 128.857C133.916 129.324 131.34 130.332 128.887 131.464C120.301 135.399 113.727 142.261 108.33 149.886C107.422 151.165 109.556 152.395 110.464 151.116Z"
                    fill="#2D2A34" />
                  <path
                    d="M292.338 164.84C292.412 164.865 291.995 164.668 291.75 164.496C291.357 164.225 290.989 163.93 290.621 163.61C289.591 162.725 288.61 161.79 287.628 160.856C284.832 158.175 282.109 155.444 279.312 152.764C273.817 147.549 267.734 141.4 260.497 138.695C258.093 137.785 255.272 137.465 253.088 139.064C250.758 140.761 250.022 143.368 251.298 145.951C253.088 149.517 256.523 152.395 259.491 154.928C262.975 157.904 266.63 160.634 270.383 163.266C275.142 166.586 280.245 170.103 286.083 171.014C288.34 171.358 291.897 171.505 293.59 169.562C295.626 167.226 292.51 165.184 290.646 164.299C289.223 163.635 287.972 165.75 289.419 166.414C290.057 166.709 290.94 167.004 291.406 167.546C291.774 167.988 291.921 167.791 291.357 168.185C290.425 168.824 288.732 168.775 287.677 168.702C284.268 168.48 280.956 166.931 278.012 165.258C275.118 163.61 272.395 161.692 269.696 159.749C266.262 157.289 262.901 154.707 259.761 151.878C257.455 149.812 254.389 147.303 253.26 144.303C252.598 142.532 254.241 140.761 255.959 140.441C258.24 140.048 260.693 141.327 262.607 142.409C268.788 145.901 274.038 151.091 279.116 155.986C281.692 158.47 284.219 161.028 286.819 163.487C288.193 164.791 289.812 166.635 291.676 167.201C293.197 167.693 293.859 165.332 292.338 164.84Z"
                    fill="#2D2A34" />
                  <path
                    d="M277.374 54.7253C282.575 47.2482 294.571 47.6664 301.341 38.5414C292.755 38.5414 283.654 37.6313 275.535 34.0404C258.24 26.4157 242.736 10.6991 223.651 8.58388C213.863 7.50167 187.688 7.8952 182.242 18.0778L182.88 17.4383C182.439 18.3975 181.997 18.7911 182.316 20.0455C189.97 11.2156 187.492 33.4501 187.394 36.5491C187.271 40.6074 189.381 47.3958 187.59 51.2327C186.167 54.258 180.427 52.1674 181.825 58.1195C189.111 57.603 199.66 52.9052 209.129 52.0936C217.592 51.3803 223.406 51.1343 231.82 52.6347C241.755 54.381 253.873 61.4645 261.503 67.7856C270.358 75.0905 297.294 76.7876 300.924 68.5727C289.346 65.6704 283.115 62.5467 277.374 54.7253Z"
                    fill="#C0F0EA" />
                  <path
                    d="M183.027 126.003C182.978 111.935 182.905 97.8907 182.856 83.822C182.758 61.4153 182.659 39.0087 182.586 16.6021L182.512 1.18059H180.059C180.182 22.8494 180.182 44.5181 180.157 66.1869C180.133 76.9598 180.108 87.7081 180.084 98.481C180.059 107.655 180.574 116.805 180.599 125.979C180.574 127.578 183.027 127.578 183.027 126.003C183.003 116.829 182.488 107.68 182.512 98.5056C182.537 87.7327 182.561 76.9598 182.586 66.2115C182.635 44.5427 182.635 22.874 182.488 1.20519C182.488 -0.368933 180.035 -0.368933 180.035 1.20519C180.084 15.2739 180.157 29.318 180.206 43.3867C180.304 65.7934 180.403 88.2 180.501 110.607C180.525 115.747 180.55 120.888 180.574 126.028C180.574 127.578 183.027 127.578 183.027 126.003Z"
                    fill="#2D2A34" />
                  <path
                    d="M181.801 122.855L181.286 124.429C175.423 121.281 174.221 128.906 181.801 129.078C190.73 129.299 186.952 124.356 181.801 122.855Z"
                    fill="#2D2A34" />
                </g>
                <defs>
                  <clipPath id="clip0_345_2726">
                    <rect width="382" height="184" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue';
import { useRegion } from '~/store/app/region';

export default defineComponent({
  name: 'RegionModal',

  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },

  emits: ['update:modelValue', 'select'],

  async setup(props, { emit }) {
    const regionStore = useRegion();
    const selectedRegion = ref(null);

    // Загружаем регионы при инициализации компонента если они еще не загружены
    if (!regionStore.listRegions.length) {
      await regionStore.fetchRegions();
    }

    // Используем listRegions вместо regions
    const regions = computed(() => regionStore.listRegions);

    const currentRegion = computed(() => regionStore.getCurrentRegion);

    watch(() => props.modelValue, (newValue) => {
      if (typeof window !== 'undefined') {  // Проверяем что мы на клиенте
        if (newValue) {
          document.body.style.overflow = 'hidden';
          selectedRegion.value = currentRegion.value;
        } else {
          document.body.style.overflow = '';
        }
      }
    }, { immediate: true });

    const handleRegionSelect = (region) => {
      emit('select', region);
      emit('update:modelValue', false);

      setTimeout(() => {
        selectedRegion.value = region;
        regionStore.setRegionId(region.id);
      }, 0);
    };

    const handleClose = () => {
      emit('update:modelValue', false);
      console.log('handleClose');
    };

    return {
      regionStore,
      selectedRegion,
      regions,
      currentRegion,
      handleRegionSelect,
      handleClose
    };
  }
});
</script>