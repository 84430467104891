<template>
    <div class="flex flex-col gap-2 items-start relative bg-transparent">
        <AppUiHeader title="Вы знаете врача?"/>

        <AppCardWithIcon type="doc" @click="appStore.type='doc'; navStore.next()"
                         :active="appStore.type==='doc'"/>

        <AppCardWithIcon type="special" @click="appStore.type='special'; navStore.next()"
                         :active="appStore.type==='special'"/>

        <AppCardWithIcon type="service" @click="appStore.type='service'; navStore.next()"
                         :active="appStore.type==='service'"/>

        <!-- <AppCardWithIcon type="home" @click="goHome()"
                         :active="appStore.type==='home'"/> -->
    </div>
</template>
<script>
import {useApp} from "~/store/app/app";
import {useNav} from "~/store/app/nav";

export default {
    setup() {
        const appStore = useApp();
        const navStore = useNav();
        return {
            appStore,
            navStore
        }
    },


    methods: {
        goHome() {
            const router = useRouter()
            router.push('/services/calling_a_pediatrician');
            this.appStore.open = false;
        }
    }
}
</script>