<template>
    <div>
        <div class="mb-4">
            <div class="font-light leading-5 text-sm text-black mb-1" :class="{'text-[#D52B1E]':checkError('name')}">
                Фамилия Имя Отчество ребенка<span v-show="checkError('name')"> - обязательное поле</span>
            </div>
            <input v-model="patientStore.patient.name" type="text"
                   class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                   placeholder="Введите имя полностью"
                   :class="{'border-[#D52B1E]':checkError('name')}">
        </div>
        <div class="mb-4">
            <div class="font-light leading-5 text-sm text-black mb-1"
                 :class="{'text-[#D52B1E]':checkError('bday') || checkError('age')}">
                Дата рождения ребенка<span v-if="checkError('bday')"> - обязательное поле</span><span
                    v-else-if="checkError('age')"> не соответствует выбору на первом экране. <span class="cursor-pointer underline" @click="navStore.step = 1">Вернитесь назад</span> и измените выбор.</span>
            </div>
            <input type="text" class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                   placeholder="дд.мм.гггг"
                   v-maska="'##.##.####'"
                   v-model="patientStore.patient.bday"
                   @blur="blurBday"
                   :class="{'border-[#D52B1E]':checkError('bday') || checkError('age')}"
            >
        </div>
        <div class="mb-4">
            <div class="font-light leading-5 text-sm text-black mb-1" :class="{'text-[#D52B1E]':checkError('phone')}">
                Телефон<span v-show="checkError('phone')"> - обязательное поле</span>
            </div>
            <input class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                   v-maska="'+7 (###) ###-##-##'"
                   placeholder="+7 (___) __-__-__"
                   type="text"
                   name="phone"
                   id="phone"
                   v-model="patientStore.patient.phone"
                   :class="{'border-[#D52B1E]':checkError('phone')}"
            >
        </div>

        <div class="mb-4">
            <div class="flex justify-between items-center mb-1">
                <div class="font-light leading-5 text-sm text-black" :class="{'text-[#D52B1E]':checkError('address')}">
                    Ваш адрес<span v-show="checkError('address')"> - обязательное поле</span>
                </div>
                <button
                    @click="homeStore.nav.currentStep = 1"
                    class="text-sm text-black hover:text-black underline"
                    aria-label="Изменить адрес"
                >
                    Изменить
                </button>
            </div>
            <input
                v-model="homeStore.address"
                type="text"
                disabled
                class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border cursor-not-allowed opacity-50"
                :class="{'border-[#D52B1E]':checkError('address')}"
            >
        </div>

        <div class="grid grid-cols-3 gap-4 mb-4">
            <div>
                <div class="font-light leading-5 text-sm text-black mb-1">
                    Подъезд
                </div>
                <input
                    v-model="patientStore.patient.front"
                    type="text"
                    class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                    placeholder="№"
                >
            </div>
            <div>
                <div class="font-light leading-5 text-sm text-black mb-1">
                    Квартира
                </div>
                <input
                    v-model="patientStore.patient.apartment"
                    type="text"
                    class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                    placeholder="№"
                >
            </div>
            <div>
                <div class="font-light leading-5 text-sm text-black mb-1">
                    Этаж
                </div>
                <input
                    v-model="patientStore.patient.floor"
                    type="text"
                    class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                    placeholder="№"
                >
            </div>
        </div>

        <div class="mb-4">
            <div class="font-light leading-5 text-sm text-black mb-1">
                Комментарий к адресу
            </div>
            <textarea
                v-model="patientStore.patient.caddress"
                class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                placeholder="Например: код домофона, как найти подъезд и т.д."
            />
        </div>

        <div class="mb-4">
            <div class="font-light leading-5 text-sm text-black mb-1" :class="{'text-[#D52B1E]':checkError('reason')}">
                Причина обращения<span v-show="checkError('reason')"> - обязательное поле</span>
            </div>
            <textarea v-model="patientStore.patient.reason"
                      class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                      :class="{'border-[#D52B1E]':checkError('reason')}"
                      placeholder="Опишите симптомы"/>
        </div>
        
        <div class="mb-4">
            <div class="text-center">
                Врач подбирается системой автоматически. К вам приедет самый быстрый ⚡
            </div>
        </div>
    </div>
</template>

<script>
import { usePatient } from "~/store/app/patient";
import { add20ToDate } from "~/libs/ui";
import { useNav } from "~/store/app/nav";
import { useHome } from "~/store/home";

export default {
    name: 'HomeStep3Form',
    data() {
        return {
            patientStore: usePatient(),
            navStore: useNav(),
            homeStore: useHome(),
            touched: false
        }
    },
    methods: {
        checkError(checkField) {
            if (!this.touched) return false;
            return this.patientStore.checkValidHome.includes(checkField);
        },
        blurBday() {
            this.patientStore.patient.bday = add20ToDate(this.patientStore.patient.bday);
        },
        validate() {
            this.touched = true;
            return this.patientStore.checkValidHome.length === 0;
        }
    }
}
</script>
