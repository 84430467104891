<template>
    <div>
        <AppUiHeader title="Пациент" />

        <div>
            <div class="mb-2">
                <div class="font-light leading-5 text-sm text-[#919499] mb-1"
                    :class="{ 'text-[#D52B1E]': hasError('name') }">
                    Введите имя ребенка полностью<span v-show="hasError('name')"> - обязательное поле</span>
                </div>
                <input 
                    v-model="patientStore.patient.name" 
                    type="text"
                    class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border-2 focus:border-[#C0F0EA] focus:outline-none"
                    placeholder="Фамилия Имя Отчество" 
                    :class="{ 'border-[#D52B1E]': hasError('name'), 'border-gray-10': !hasError('name') }"
                    aria-label="Имя ребенка"
                    @keydown.enter.prevent="handleNext"
                    @input="debouncedValidate('name')"
                    @blur="validateField('name')"
                    data-field="name"
                >
            </div>
            <div class="mb-4">
                <div class="self-stretch inline-flex justify-start items-center gap-2">
                    <button 
                        type="button"
                        class="w-6 h-6 relative rounded-[5px]"
                        :class="patientStore.orvi ? 'bg-[#2d2a34]' : 'bg-white border border-[#edeef2]'"
                        @click="toggleOrvi"
                        aria-label="У ребёнка есть признаки ОРВИ"
                    >
                        <div v-if="patientStore.orvi" class="w-4 h-4 left-[4px] top-[4px] absolute">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="15" height="15" stroke="" />
                                <path d="M2.66699 8.66666L5.76462 11.7643C5.8948 11.8945 6.10585 11.8945 6.23603 11.7643L13.3337 4.66666" stroke="white" stroke-width="2" stroke-linecap="round" />
                            </svg>
                        </div>
                    </button>
                    <div class="flex-1 justify-start text-[#2c2934] text-base cursor-pointer" @click="toggleOrvi">
                        У ребёнка есть признаки ОРВИ
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <div class="font-light leading-5 text-sm text-[#919499] mb-1"
                    :class="{ 'text-[#D52B1E]': hasBirthdayError }">
                    <template v-if="!hasBirthdayError">
                        Дата рождения
                    </template>
                    <template v-else-if="hasError('bday')">
                        Дата рождения - обязательное поле
                    </template>
                    <template v-else-if="hasError('age18')">
                        Введите дату рождения ребёнка в возрасте до 18 лет
                    </template>
                    <template v-else-if="hasError('age')">
                        {{ textAgeError }}
                    </template>
                </div>
                <input 
                    type="text" 
                    class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border-2 focus:border-[#C0F0EA] focus:outline-none"
                    placeholder="дд.мм.гггг" 
                    v-maska="'##.##.####'" 
                    v-model="patientStore.patient.bday" 
                    @blur="handleBirthdayBlur"
                    @input="debouncedValidate('bday')"
                    :class="{ 'border-[#D52B1E]': hasBirthdayError, 'border-gray-10': !hasBirthdayError }"
                    aria-label="Дата рождения"
                    data-field="bday"
                >
            </div>
            <div class="mb-4">
                <div class="font-light leading-5 text-sm text-[#919499] mb-1"
                    :class="{ 'text-[#D52B1E]': hasError('phone') }">
                    Телефон<span v-show="hasError('phone')"> - обязательное поле</span>
                </div>
                <input 
                    class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border-2 focus:border-[#C0F0EA] focus:outline-none"
                    v-maska="'+7 (###) ###-##-##'" 
                    placeholder="+7 (___) __-__-__" 
                    type="tel" 
                    name="phone" 
                    id="phone"
                    v-model="patientStore.patient.phone" 
                    @input="debouncedValidate('phone')"
                    @blur="validateField('phone')"
                    :class="{ 'border-[#D52B1E]': hasError('phone'), 'border-gray-10': !hasError('phone') }"
                    aria-label="Телефон"
                    data-field="phone"
                >
            </div>
            <div class="mb-4">
                <div class="font-light leading-5 text-sm text-[#919499] mb-1"
                    :class="{ 'text-[#D52B1E]': hasError('reason') }">
                    Причина обращения<span v-show="hasError('reason')"> - обязательное поле</span>
                </div>
                <textarea 
                    v-model="patientStore.patient.reason"
                    class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border-2 focus:border-[#C0F0EA] focus:outline-none"
                    :class="{ 'border-[#D52B1E]': hasError('reason'), 'border-gray-10': !hasError('reason') }" 
                    placeholder="Опишите симптомы"
                    aria-label="Причина обращения"
                    data-field="reason"
                />
            </div>
        </div>

        <AppUiHeader title="О вас заботится" />
        <AppStep6About class="mb-8" />
        <AppStep6Promo class="mb-8" />

        <div class="flex flex-col gap-6 items-start relative w-full bg-transparent">
            <div class="flex gap-2 items-start self-stretch relative w-full bg-transparent">
                <div>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="6" fill="#292B33"></rect>
                        <path d="M5.8335 9.99996L8.41486 12.5813C8.52333 12.6898 8.69921 12.6898 8.80769 12.5813L14.7224 6.66663" stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
                    </svg>
                </div>
                <p class="font-light leading-4 text-xs text-[#2d2a34]">
                    Я даю согласие на обработку персональных данных, а также ознакомился с правилами проведения онлайн-консультации.
                </p>
            </div>
            
            <AppUiBtn 
                text="Записаться" 
                type="allow" 
                @click="handleNext"
                v-if="isFormValid" 
            />
            <AppUiBtn 
                text="Записаться" 
                class="opacity-25 cursor-not-allowed" 
                type="disallow" 
                v-else
                @click="handleInvalidSubmit"
            />
        </div>
    </div>
</template>

<script>
import { usePatient } from "~/store/app/patient";
import { add20ToDate } from "~/libs/ui";
import { useNav } from "~/store/app/nav";
import { useDocs } from "~/store/docs";
import { useApp } from "~/store/app/app";
import { generateTextAge } from "~/libs/ui";

export default {
    name: 'AppStep6Index',
    
    data() {
        return {
            showErrors: false,
            validationErrors: [],
            debouncedValidate: null,
            debounceTimer: null,
            validationRules: {
                name: {
                    required: true,
                    message: 'Введите имя ребенка полностью'
                },
                phone: {
                    required: true,
                    format: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
                    message: 'Введите корректный номер телефона'
                },
                bday: {
                    required: true,
                    format: /^\d{2}\.\d{2}\.\d{4}$/,
                    message: 'Введите корректную дату рождения'
                }
            }
        }
    },
    
    created() {
        this.debouncedValidate = this.createDebounce(this.validateField, 1500);
    },
    
    beforeUnmount() {
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
    },
    
    setup() {
        const appStore = useApp();
        const docStore = useDocs();
        const patientStore = usePatient();
        const navStore = useNav();

        return {
            patientStore,
            navStore,
            appStore,
            docStore
        }
    },

    computed: {
        isFormValid() {
            const { patient } = this.patientStore;
            const hasData = patient.name && patient.phone && patient.bday;
            return hasData && this.validationErrors.length === 0;
        },

        hasBirthdayError() {
            return this.hasError('bday') || this.hasError('age') || this.hasError('age18');
        },

        textAgeError() {
            // const doc = this.docStore.getDocById(this.appStore.docId);
            const doc = this.docStore.getDocById(this.appStore.hotDoc.id);


            if (doc) {
                const age = generateTextAge(doc.attributes.age_from, doc.attributes.age_to);
                return `Выбранный врач ведет только ${age.toLowerCase()}`;
            }
            return '';
        }
    },

    methods: {
        createDebounce(func, delay) {
            return (...args) => {
                if (this.debounceTimer) {
                    clearTimeout(this.debounceTimer);
                }
                this.debounceTimer = setTimeout(() => {
                    func.apply(this, args);
                }, delay);
            };
        },

        hasError(field) {
            if (!this.showErrors) return false;
            return this.validationErrors.includes(field);
        },

        validateRequired(value) {
            return value && value.length > 0;
        },

        validateFormat(value, format) {
            return format.test(value);
        },

        validateAge(birthDate) {
            const today = new Date();
            const [day, month, year] = birthDate.split('.');
            const birthDateObj = new Date(`${year}-${month}-${day}`);
            const ageDate = new Date(today - birthDateObj);
            const age = Math.abs(ageDate.getUTCFullYear() - 1970);
            
            return {
                age,
                isAdult: age <= 18
            };
        },

        validateAgeRange(age, doc) {
            if (!doc) return true;
            
            const isInRange = doc.attributes.age_from !== null &&
                doc.attributes.age_to !== null &&
                doc.attributes.age_from !== undefined &&
                doc.attributes.age_to !== undefined &&
                age >= +doc.attributes.age_from &&
                age <= +doc.attributes.age_to;

            return isInRange;
        },

        validateField(field) {
            const { patient } = this.patientStore;
            const errors = [...this.validationErrors];
            
            // Удаляем все ошибки, связанные с текущим полем
            const index = errors.indexOf(field);
            if (index > -1) {
                errors.splice(index, 1);
            }

            // Удаляем ошибки, связанные с возрастом, если валидируем дату рождения
            if (field === 'bday') {
                const ageErrors = ['age18', 'age'];
                ageErrors.forEach(error => {
                    const errorIndex = errors.indexOf(error);
                    if (errorIndex > -1) {
                        errors.splice(errorIndex, 1);
                    }
                });
            }

            const value = patient[field];
            const rules = this.validationRules[field];
            
            if (!rules) return;

            // Проверка обязательности поля
            if (rules.required && !this.validateRequired(value)) {
                errors.push(field);
                this.validationErrors = errors;
                this.showErrors = true;
                return;
            }

            // Проверка формата
            if (rules.format && !this.validateFormat(value, rules.format)) {
                errors.push(field);
                this.validationErrors = errors;
                this.showErrors = true;
                return;
            }

            // Специальная валидация для даты рождения
            if (field === 'bday' && value && value.length === 10) {
                const { age, isAdult } = this.validateAge(value);
                const doc = this.appStore.hotDoc;

                if (!isAdult) {
                    errors.push('age18');
                }

                if (!this.validateAgeRange(age, doc)) {
                    errors.push('age');
                }
            }

            this.validationErrors = errors;
            this.showErrors = true;
        },

        validateForm() {
            const errors = [];
            const { patient } = this.patientStore;
            
            // Проверяем все поля по правилам
            Object.keys(this.validationRules).forEach(field => {
                const value = patient[field];
                const rules = this.validationRules[field];

                if (rules.required && !this.validateRequired(value)) {
                    errors.push(field);
                } else if (rules.format && !this.validateFormat(value, rules.format)) {
                    errors.push(field);
                }
            });

            // Специальная валидация для даты рождения
            if (patient.bday && patient.bday.length === 10) {
                const { age, isAdult } = this.validateAge(patient.bday);
                const doc = this.appStore.hotDoc;

                if (!isAdult) {
                    errors.push('age18');
                }

                if (!this.validateAgeRange(age, doc)) {
                    errors.push('age');
                }
            }

            this.validationErrors = errors;
            this.showErrors = true;
            return errors.length === 0;
        },

        scrollToError() {
            const firstErrorField = this.validationErrors[0];
            if (!firstErrorField) return;

            const fieldElement = document.querySelector(`[data-field="${firstErrorField}"]`);
            if (!fieldElement) return;

            fieldElement.scrollIntoView({ 
                behavior: 'smooth',
                block: 'center'
            });
        },

        handleBirthdayBlur() {
            this.patientStore.patient.bday = add20ToDate(this.patientStore.patient.bday);
            // if (this.patientStore?.patient?.bday?.length === 10) {
            this.validateField('bday');
            // }
        },

        toggleOrvi() {
            this.patientStore.orvi = !this.patientStore.orvi;
        },

        handleNext() {
            if (this.validateForm()) {
                this.navStore.next();
            } else {
                this.$nextTick(() => {
                    this.scrollToError();
                });
            }
        },

        handleInvalidSubmit() {
            this.validateForm();
            this.$nextTick(() => {
                this.scrollToError();
            });
        }
    }
}
</script>