<template>
    <div class="quiz">
        <div class="mb-8" v-show="appStore.showGroup">
            <AppUiHeader title="Какая услуга нужна?" />
            <AppCardBtn v-for="group of appStore.hotListGroups" :key="group.id" :title="group.attributes.title"
                :active="appStore.groupId === group.id" @click="selectGroup(group.id)" />
        </div>
    </div>
</template>
<script>
import { useApp } from "~/store/app/app";
import { useNav } from "~/store/app/nav";

export default {
    setup() {
        const appStore = useApp();
        const navStore = useNav();

        return {
            appStore,
            navStore
        }
    },
    methods: {
        selectGroup(id) {
            this.appStore.groupId = id;
            this.navStore.next();
        }
    },
    mounted() {
        this.appStore.clearApp(4);
    }
}
</script>
