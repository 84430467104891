<template>
    <div class="md:max-w-[380px] pb-12">
        <img :src="mapUrl" alt="" class="w-full" v-if="mapUrl">
        <div class="px-12 relative mt-[-60px]">
            <UIBtnGreen text="Все филиалы" class="w-full" @click="goToAllClinics"/>
        </div>
    </div>
</template>

<script>
import { useRegion } from "~/store/app/region";

export default {
    async setup() {
        const regionStore = useRegion();
        //base url
        // const baseUrl = useRuntimeConfig().public.apiBase;

        return {
            regionStore,
            // baseUrl,
            baseUrl: 'https://admin.fomin-kids.ru',
        }
    },
    computed: {
        mapUrl() {
            if (this.regionStore?.regionInfo?.attributes?.image?.data?.[0]?.attributes?.url) {
                return this.baseUrl + this.regionStore.regionInfo.attributes.image.data[0].attributes.url;
            } else {
                return false;
            }
        }
    },
    methods: {
        goToAllClinics() {
            this.$router.push('/contacts');
        }
    }
}
</script>