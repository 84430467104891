<template>
    <div class="rounded-2xl border border-[#efedf2] p-4 flex flex-col gap-4 items-start relative w-full bg-white">
        <div class="flex gap-2 items-end self-stretch relative w-full bg-transparent cursor-pointer"
             @click="open = !open">
            <div class="flex gap-1 items-center flex-1 relative w-full bg-transparent">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="8" fill="#292B33"></circle>
                    <path d="M15 9L9 15" stroke="white" stroke-width="1.5" stroke-linecap="round"></path>
                    <circle cx="14.5" cy="14.5" r="1" fill="white" stroke="white"></circle>
                    <circle cx="9.5" cy="9.5" r="1" fill="white" stroke="white"></circle>
                </svg>
                <p class="font-medium leading-5 text-sm text-[#292b33]">Есть&nbsp;промокод</p></div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!open">
                <path d="M16 10L12 14L8 10" stroke="#9E9BA3" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
            </svg>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                <path d="M16 14L12 10L8 14" stroke="#9E9BA3" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"></path>
            </svg>
        </div>

        <input class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border-2 focus:border-[#C0F0EA] focus:outline-none border-gray-10"
               placeholder="Введите промокод"
               type="text"
               name="promo"
               id="promo"
               v-if="open"
               v-model="patientStore.patient.promo"
        >
    </div>
</template>

<script>
import {useApp} from "~/store/app/app";
import {usePatient} from "~/store/app/patient";

export default {
    data() {
        return {
            open: false,
        }
    },
    setup() {
        const appStore = useApp();
        const patientStore = usePatient();
        return {
            appStore,
            patientStore
        }
    },

}
</script>