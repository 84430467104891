<template>
    <TransitionRoot as="template" :show="appStore.open">
        <Dialog as="div" class="fixed top-[68px] bottom-0 left-0 right-0 md:inset-0 overflow-y-auto dialog-app z-20"
            @close="appStore.open = false">
            <div class="flex min-h-full md:min-h-screen items-start justify-end pb-[68px]">
                <DialogOverlay v-if="!appStore.debug"
                    class="fixed inset-0 top-[68px] md:inset-0 bg-orange-50 bg-opacity-90" />

                <div class="pointer-events-none fixed inset-y-0 md:top-0 right-0 flex max-w-full">
                    <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enter-from="translate-x-full" enter-to="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
                        leave-to="translate-x-full">
                        <div
                            class="pointer-events-auto flex flex-col gap-2 items-center relative w-screen md:w-[420px] bg-white h-full md:h-screen">
                            <AppUiSnail class="hidden md:block" />

                            <HeaderMobile class="md:hidden" />

                            <div
                                class="px-4 flex justify-between items-center self-stretch relative w-full h-12 bg-transparent">
                                <div @click="navStore.prev()" class="w-[34px] h-10 flex justify-between items-center"
                                    :class="{ 'cursor-pointer': navStore.checkPrev() }">
                                    <svg v-if="appStore.mode === 'app' && navStore.checkPrev()" width="14" height="11"
                                        viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 5.5L13 5.5M1 5.5L5 1M1 5.5L5 10" stroke="#292B33" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <p class="tracking-[0.14em] uppercase text-center leading-6 text-sm text-[#292b33]">
                                    {{ title }}</p>
                                <div class="rounded-3xl p-1 flex gap-1 items-start relative bg-[#f8f7fa] cursor-pointer"
                                    @click="setIsOpen(true)">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.75735 7.75735L16.2426 16.2426" stroke="#9E9BA3" stroke-width="1.5"
                                            stroke-linecap="round"></path>
                                        <path d="M16.2426 7.75735L7.75736 16.2426" stroke="#9E9BA3" stroke-width="1.5"
                                            stroke-linecap="round"></path>
                                    </svg>
                                </div>
                            </div>
                            <App v-if="appStore.mode === 'app'" />
                            <AppOrder v-if="appStore.mode === 'order'" />

                            <ClientOnly>
                                <UIPopupBeforeClose :isOpen="isOpen" @setIsOpen="setIsOpen"
                                    :mess-title="'Возникли трудности?'"
                                    :mess-subtitle="'Напишите нам в WhatsApp'" />
                            </ClientOnly>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { useApp } from "~/store/app/app";
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import { useNav } from "~/store/app/nav";
import { useOrder } from "~/store/app/order";


export default {
    setup() {
        const appStore = useApp();
        const navStore = useNav();
        return {
            appStore,
            title: computed(() => appStore.mode === 'app' ? 'Запись на приём' : 'Заявка'),
            navStore
        }
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        setIsOpen(value) {
            this.isOpen = value;
        }
    },
    watch: {
        'appStore.open': (val) => {
            const appStore = useApp();
            const orderStore = useOrder();

            if (val === false) {
                appStore.clearApp(0)
                orderStore.status = null;
            }
        }
    },
    components: {
        XIcon,
        Dialog,
        DialogTitle,
        DialogOverlay,
        TransitionRoot,
        TransitionChild
    },
}
</script>
