<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.8636 10.3397L9.53656 10.9893C7.2889 9.85289 5.90088 8.5472 5.09295 6.51215L5.71506 3.15025L4.53952 0H1.50897C0.597629 0 -0.119813 0.758666 0.0167271 1.6663C0.356058 3.93171 1.35789 8.04007 4.28502 10.9893C7.35919 14.0866 11.7875 15.4305 14.2242 15.9654C15.1654 16.1713 16 15.4322 16 14.461V11.5436L12.8636 10.3397Z"
            fill="#2D2A34" />
    </svg>
</template>

<script>
export default {
    name: 'IconPhone'
}
</script>
