<template>
    <div class="w-full h-12 px-6 py-3 bg-[#D00273] rounded-3xl justify-center items-center gap-2 inline-flex  cursor-pointer" :class="[justifyClass]">
        <div class="text-white text-base font-normal leading-normal">
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    props: ["text", "justify"],
    computed: {
        justifyClass() {
            if (this.justify) {
                return `justify-${this.justify}`;
            }
            return  `justify-center`;
        }
    }
}
</script>
