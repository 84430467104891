<template>
  <TransitionRoot as="template" :show="programStore.order">
    <Dialog
      as="div"
      class="fixed inset-0 dialog-app z-20"
      @close="programStore.order = false"
    >
      <div class="absolute inset-0">
        <DialogOverlay class="absolute inset-0 bg-orange-50 bg-opacity-90" />

        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-16 ">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full">
            <div
              class="pointer-events-auto flex flex-col gap-2 items-center relative w-screen md:w-[420px] bg-white">
              <div
                class="px-4 pt-3 flex justify-between items-center self-stretch relative w-full h-10 bg-transparent">
                <div></div>
                <div class="rounded-3xl p-1 flex gap-1 items-start relative bg-[#f8f7fa] cursor-pointer"
                     @click="programStore.order = false; programStore.status = false; ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.75735 7.75735L16.2426 16.2426" stroke="#9E9BA3"
                          stroke-width="1.5"
                          stroke-linecap="round"></path>
                    <path d="M16.2426 7.75735L7.75736 16.2426" stroke="#9E9BA3"
                          stroke-width="1.5"
                          stroke-linecap="round"></path>
                  </svg>
                </div>
              </div>

              <div class="space-y-6 sm:space-y-0 sm:py-0 overflow-y-scroll h-full bg-white"
                   v-if="programStore.status === false">
                <template v-if="programStore.type === 'custom'">
                  <ProgramBasketCustom />
                </template>
                <template v-else-if="programStore.type === 'complex'">
                  <ProgramBasketComplex />
                </template>

                <div class="px-6 py-4">
                  <div class="text-xl font-medium">Контактная информация</div>
                  <ProgramBasketForm />
                </div>
              </div>
              <div class="space-y-6 sm:space-y-0 sm:py-0 overflow-y-scroll h-full bg-white" v-else>
                  <ProgramBasketSuccess />
              </div>

        </div>
        </TransitionChild>
      </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { useApp } from "~/store/app/app";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import { useNav } from "~/store/app/nav";
import { useOrder } from "~/store/app/order";
import { usePrograms } from "~/store/programs";


export default {
  setup() {
    const programStore = usePrograms();
    return {
      programStore
    };
  },
  watch: {
    // "appStore.open": (val) => {
    //     const appStore = useApp();
    //     const orderStore = useOrder();
    //
    //     if (val === false) {
    //         appStore.clearApp(0);
    //         orderStore.status = null;
    //     }
    // }
  },
  computed: {
    title() {
      return `Оформление годовой программы «${this.programStore.program}»`;
    }
  },
  components: {
    XIcon,
    Dialog,
    DialogTitle,
    DialogOverlay,
    TransitionRoot,
    TransitionChild
  }
};
</script>
