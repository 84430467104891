<template>
    <div id="top" class="pb-4"></div>
    <div class="mb-4">
        <div class="font-light leading-5 text-sm text-black mb-1">
            Ваше имя
        </div>
        <input v-model="orderStore.name" type="text"
               class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
               placeholder="Введите имя"
        >
    </div>
    <div class="mb-4">
        <div class="font-light leading-5 text-sm text-black mb-1">
            Телефон
        </div>
        <input class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
               v-maska="'+7 (###) ###-##-##'"
               placeholder="+7 (___) __-__-__"
               type="text"
               name="phone"
               id="phone"
               v-model="orderStore.phone"
        >
    </div>
    <div class="mb-4">
        <div class="font-light leading-5 text-sm text-black mb-1">
            Комментарий
        </div>
        <textarea
                v-model="orderStore.comment"
                class="bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                placeholder=""/>
    </div>
    <div class="mt-3">
        <AppUiBtn text="Отправить" @click="send"/>
    </div>
</template>
<script>
import {useApp} from "~/store/app/app";
import {useOrder} from "~/store/app/order";
import {usePatient} from "~/store/app/patient";
import {useStat} from "~/store/stat";

export default {
    setup() {
        const appStore = useApp();
        const orderStore = useOrder();
        const patientStore = usePatient();

        return {
            appStore,
            orderStore,
            patientStore,
        }
    },
    mounted() {
        this.orderStore.name = this.patientStore?.patient?.name?.length ? this.patientStore.patient.name : "";
        this.orderStore.phone = this.patientStore?.patient?.phone?.length ? this.patientStore.patient.phone : "";
    },
    methods: {
        async send() {
            //
            if (this.orderStore.comment.indexOf('Пустой') !== -1) {
                const statStore = useStat();
                statStore.goal('empty');
                statStore.callTouch({
                    fio: this.orderStore.name,
                    phone: this.orderStore.phone,
                    subject: 'Заявка пустой график',
                    tag: 'order',
                })
            }
            this.orderStore.status = true;
            await this.orderStore.send();
        }
    }
}
</script>
