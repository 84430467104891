<template>
  <div @click="openRegionModal"
    class="h-10 pl-4 pr-2 bg-[#f4f6f9] rounded-full inline-flex justify-start items-center gap-1 cursor-pointer">
    <div
      class="relative justify-end text-[#2d2a34] text-[10px] font-bold font-['Circe'] uppercase leading-none tracking-wide whitespace-nowrap">
      {{ regionStore.getCurrentRegion?.name ? regionStore.getCurrentRegion?.name : 'Москва' }}
    </div>
    <div class="w-4 h-4 flex items-center justify-center">
      <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 1L4 4L1 1" stroke="#919499" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  </div>
  <RegionModal v-model="isOpen" @select="handleRegionSelect" @close="handleClose" :key="`region-modal-${regionStore.regionId}`" />
</template>

<script>
import { defineComponent } from 'vue';
import { useRegion } from '~/store/app/region';
export default defineComponent({
  setup() {
    const regionStore = useRegion();

    return {
      regionStore
    }
  },

  data() {
    return {
      isOpen: false
    }
  },

  methods: {
    openRegionModal() {
      this.$emit('openRegionModal');
      this.isOpen = true;
      
      this.$nextTick(() => {
        const appRoot = document.getElementById('app') || document.body;
        const regionModals = document.querySelectorAll('.region-modal-container');
        
        if (regionModals.length > 0) {
          regionModals.forEach(modal => {
            if (modal.parentElement !== appRoot) {
              appRoot.appendChild(modal);
            }
          });
        }
      });

      
    },
    
    handleRegionSelect(region) {
      this.isOpen = false;
      this.$emit('select', region);
    },

    handleClose() {
      this.isOpen = false;
    }
  }
});
</script>
