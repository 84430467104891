<template>
    <div class="flex justify-start md:justify-between items-center gap-2">
        <div v-if="showLabel">
            <div class="text-sm text-[#919399]">{{ label }}</div>
        </div>
        <div class="flex gap-2" v-if="!mobile">
            <a href="https://t.me/fominclinic_kids_bot"
                class="flex items-center justify-center border rounded-full border-[#EFEDF2] w-10 h-10 text-[#2D2A34] hover:text-black"
                aria-label="Telegram" tabindex="0" @click="handleTelegramClick" @keydown="handleKeyDown">
                <UIIconTg />
            </a>

            <a class="flex items-center justify-center border rounded-full border-[#EFEDF2] w-10 h-10 text-[#2D2A34] hover:text-black"
                href="https://wa.me/79161006469" target="_blank" aria-label="WhatsApp" tabindex="0"
                @click="handleWhatsAppClick" @keydown="handleKeyDown">
                <UIIconWa />
            </a>
        </div>
        <div class="flex gap-2" v-if="mobile">
            <span
                class="flex items-center justify-center border rounded-full border-[#EFEDF2] w-10 h-10 text-[#2D2A34] hover:text-black"
                target="_blank" aria-label="WhatsApp" tabindex="0" @click="handlePhoneClick" @keydown="handleKeyDown">
                <UIIconWa />
            </span>
            <a :href="`tel:${phone}`"
                class="flex items-center justify-center border rounded-full border-[#EFEDF2] w-10 h-10 text-[#2D2A34] hover:text-black"
                target="_blank" aria-label="phone" tabindex="0">
                <UIIconPhone />
            </a>

            <ClientOnly>
                <UIPopupMessangers :isOpen="isOpen" @setIsOpen="setIsOpen" :mess-title="'Выберите мессенджер'"
                    :mess-subtitle="'Мы всегда рады помочь вам записаться в клинику и ответить на вопросы'" />
            </ClientOnly>
        </div>
    </div>
</template>

<script>
import { useRegion } from '~/store/app/region';
export default {
    name: 'MessengersBlock',
    props: {
        label: {
            type: String,
            default: 'Написать нам:'
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        mobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: false
        }
    },
    setup() {
        const regionStore = useRegion();

        const handleTelegramClick = () => {
            // Any additional logic for Telegram click
        };

        const handleWhatsAppClick = () => {
            // Any additional logic for WhatsApp click
        };

        const handleKeyDown = (event) => {
            if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                event.target.click();
            }
        };

        return {
            handleTelegramClick,
            handleWhatsAppClick,
            handleKeyDown,
            regionStore
        };
    },
    methods: {
        handlePhoneClick() {
            this.isOpen = true;
        },
        setIsOpen(value) {
            this.isOpen = value;
        }
    },
    computed: {
        phone() {
            return this.regionStore?.regionInfo?.attributes?.phone ? this.regionStore.regionInfo.attributes.phone : '+7 495 812-98-21';
        }
    }
}
</script>