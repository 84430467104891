<template>
    <div>
        <div
            class="overflow-hidden rounded-2xl px-6 py-8 flex flex-col gap-10 items-center relative w-[328px] bg-transparent"
            style="margin: 0 auto">
            <div class="flex flex-col gap-4 items-center relative bg-transparent">
                <div class="overflow-hidden relative w-[120px] h-[120px] bg-transparent">
                    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M50.0004 76.3158C53.4568 76.32 56.8801 75.6413 60.0734 74.3185C63.2667 72.9958 66.1672 71.055 68.6083 68.6079C71.0554 66.1669 72.9961 63.2664 74.3189 60.073C75.6417 56.8797 76.3204 53.4565 76.3161 50C76.3204 46.5435 75.6417 43.1203 74.3189 39.927C72.9961 36.7336 71.0554 33.8331 68.6083 31.3921C66.1672 28.945 63.2667 27.0042 60.0734 25.6815C56.8801 24.3587 53.4568 23.68 50.0004 23.6842C46.5439 23.68 43.1207 24.3587 39.9273 25.6815C36.734 27.0042 33.8335 28.945 31.3925 31.3921C28.9453 33.8331 27.0046 36.7336 25.6818 39.927C24.3591 43.1203 23.6803 46.5435 23.6846 50C23.6803 53.4565 24.3591 56.8797 25.6818 60.073C27.0046 63.2664 28.9453 66.1669 31.3925 68.6079C33.8335 71.055 36.734 72.9958 39.9273 74.3185C43.1207 75.6413 46.5439 76.32 50.0004 76.3158Z"
                            fill="#72E0D8" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M69.1368 96.1998C63.0698 98.7129 56.5667 100.004 50 100C43.4333 100.004 36.9302 98.7129 30.8632 96.1998C24.796 93.6866 19.2844 90.0011 14.6441 85.3544C9.99818 80.7144 6.31315 75.2034 3.80023 69.1368C1.28712 63.0698 -0.00424528 56.5667 1.04846e-05 50C-0.0042434 43.4333 1.28712 36.9302 3.80023 30.8632C6.31328 24.7963 9.99855 19.285 14.6448 14.6448C19.285 9.99855 24.7963 6.31328 30.8632 3.80023C36.9302 1.28712 43.4333 -0.0042434 50 1.04846e-05C56.5667 -0.00424528 63.0698 1.28712 69.1368 3.80023C75.2034 6.31315 80.7144 9.99818 85.3544 14.6441C90.0011 19.2844 93.6866 24.796 96.1998 30.8632C98.7129 36.9302 100.004 43.4333 100 50C100.004 56.5667 98.7129 63.0698 96.1998 69.1368C93.6867 75.2037 90.0014 80.715 85.3551 85.3551C80.715 90.0014 75.2037 93.6867 69.1368 96.1998ZM76.3158 50C76.32 53.4565 75.6413 56.8797 74.3185 60.073C72.9958 63.2664 71.055 66.1669 68.6079 68.6079C66.1669 71.055 63.2664 72.9958 60.073 74.3185C56.8797 75.6413 53.4565 76.32 50 76.3158C46.5435 76.32 43.1203 75.6413 39.927 74.3185C36.7336 72.9958 33.8331 71.055 31.3921 68.6079C28.945 66.1669 27.0042 63.2664 25.6815 60.073C24.3587 56.8797 23.68 53.4565 23.6842 50C23.68 46.5435 24.3587 43.1203 25.6815 39.927C27.0042 36.7336 28.945 33.8331 31.3921 31.3921C33.8331 28.945 36.7336 27.0042 39.927 25.6815C43.1203 24.3587 46.5435 23.68 50 23.6842C53.4565 23.68 56.8797 24.3587 60.073 25.6815C63.2664 27.0042 66.1669 28.945 68.6079 31.3921C71.055 33.8331 72.9958 36.7336 74.3185 39.927C75.6413 43.1203 76.32 46.5435 76.3158 50Z"
                              fill="#DAF2EF" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M63.8561 41.4074C64.2415 41.7928 64.2415 42.4177 63.8561 42.8031L48.0667 58.5925C47.6813 58.9779 47.0565 58.9779 46.6711 58.5925L38.7763 50.6978C38.391 50.3124 38.391 49.6876 38.7763 49.3022C39.1617 48.9168 39.7866 48.9168 40.1719 49.3022L47.3689 56.4991L62.4605 41.4074C62.8459 41.0221 63.4708 41.0221 63.8561 41.4074Z"
                              fill="#FFFBFB" />
                    </svg>

                </div>
                <p class="font-medium text-2xl">
                    Спасибо за заявку!</p>

                <p class="text-center">
                    Вы оформили заявку на годовую программу
                </p>


                <br>
                <br>
                <!--                <UIBtnGreen text="Закрыть" class="w-full" @click="programStore.order = false" />-->
            </div>
        </div>
    </div>
</template>

<script>
import { useApp } from "~/store/app/app";
import { usePrograms } from "~/store/programs";

export default {
    setup() {
        const appStore = useApp();
        const programStore = usePrograms();
        return {
            programStore,
            appStore
        };
    }
};
</script>
