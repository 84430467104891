<template>
    <div class="flex flex-col gap-6 mb-9 md:mb-0">
        <div class="flex flex-col gap-2" v-if="regionStore?.regionInfo?.attributes?.phone">
            <div>
                <div class="text-sm text-[#919399]">Для звонков:</div>
            </div>
            <div>
                <a class="text-2xl font-medium" href="tel:{{ regionStore.regionInfo.attributes.phone }}">{{
                    regionStore.regionInfo.attributes.phone }}</a>
            </div>
        </div>

        <div class="flex flex-col gap-2" v-if="regionStore?.phone_rf">
            <div>
                <div class="text-sm text-[#919399]">Для звонков по России:</div>
            </div>
            <div>
                <a class="text-2xl font-medium" :href="`tel:${regionStore.phone_rf}`">
                    {{ regionStore.phone_rf }}</a>
            </div>
        </div>

        <UIMessengersBlock />

        <div class="flex justify-start md:justify-between items-center gap-2">
            <div>
                <div class="text-sm text-[#919399]">Почта:</div>
            </div>
            <div>
                <div class=""><a class="text-xl text-[#919399]"
                        href="mail:kids@fomin-clinic.ru">kids@fomin-clinic.ru</a></div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRegion } from "~/store/app/region";

export default {
    setup() {
        const regionStore = useRegion();

        return {
            regionStore,
        }
    }
}
</script>