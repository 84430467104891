<template>
  <div>
    <ClientOnly>
      <UIVirtualHelper />
      <RegionModal 
        v-model="uiStore.showRegionModal"
        @select="handleRegionSelect"
      />
    </ClientOnly>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useUi } from '~/store/ui';
import { useRegion } from '~/store/app/region';
import RegionModal from '~/components/RegionModal.vue';

export default defineComponent({
  components: {
    RegionModal
  },
  setup() {
    const uiStore = useUi();
    const regionStore = useRegion();

    const handleRegionSelect = (region) => {
      // Region was selected, update the region ID
      regionStore.setRegionId(region.id);
      
      // Close the modal
      uiStore.toggleRegionModal(false);
    };

    return {
      uiStore,
      handleRegionSelect
    };
  }
});
</script>
<style lang=""></style>
