<template>
    <div class="flex p-8 flex-col gap-2 items-center relative bg-transparent"
         v-if="show" >
        <AppUiIconAttention/>

        <div class="text-center mb-8 font-light leading-5 text-base text-[#292b33]">
            Для пациентов с признаками ОРВИ работает
            <a class="underline" :href="clinic.attributes.backdoorImg" target="_blank">индивидуальный вход</a>
        </div>

    </div>
</template>

<script>
import {useNav} from "~/store/app/nav";
import {useApp} from "~/store/app/app";

export default {
    setup() {
        const navStore = useNav();
        const appStore = useApp();
        const clinic = appStore.hotClinic;
        let show = false;

        if (clinic?.attributes?.backdoorImg?.length && appStore.isPediatrist && appStore.listReasons.findIndex(item => item.select && item.infected) !== -1) {
            show = true;
        }

        return {
            navStore,
            clinic,
            show
        }
    }

}
</script>