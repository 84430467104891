<template>
    <div>
        <div>
            <Loader></Loader>
        </div>
        <div class="bg-orange-50" :key="`region-${regionStore.regionId}`">
            <header>
                <HeaderNew class="bg-[#F5F7FA]" />
            </header>

            <template  v-if="regionStore.regionInfo">
                <div style="min-height: 90vh;">
                    <slot />
                </div>

                <TheModals />
                <footer :key="`footer-${regionStore.regionId}`">
                    <Footer />
                </footer>

                <SlotDialog></SlotDialog>
                <SlotOrder></SlotOrder>


                <Alert />
                <PromoVacc2 />

                <AppDialog></AppDialog>
                <HomeDialog></HomeDialog>
                <ProgramDialog></ProgramDialog>

                <client-only>
                    <checkInternet></checkInternet>
                </client-only>
            </template>
        </div>
    </div>
</template>

<script>
import Loader from "../components/Loader";
import { useClinics } from "~/store/clinics";
import { useUi } from "~/store/ui";
import { useSlots } from "~/store/slots";
import { useRegion } from "~/store/app/region";

export default {
    components: { Loader },
    async setup() {
        const regionStore = useRegion();
        const clinicsStore = useClinics();
        const slotsStore = useSlots();
        const uiStore = useUi();
        const route = useRoute();
        useHead(() => ({
            link: [
                {
                    rel: "canonical",
                    href: "https://kids.fomin-clinic.ru" + route.path
                }
            ]
        }));

        if (!regionStore.listRegions.length) {
            await regionStore.fetchRegions();
        }

        if (!clinicsStore.listClinics.length) {
            await clinicsStore.fetchClinics();
        }

        if (!regionStore.info) {
            await regionStore.fetchInfo();
        }

        return {
            regionStore,
            uiStore,
            clinicsStore,
            slotsStore,
        };
    },
    async mounted() {
        await this.setRegionFromUrl();
        await this.regionStore.setRegion();
        await this.regionStore.fetchInfo();

    },
    methods: {
        async setRegionFromUrl() {
            const regionStore = useRegion();
            const urlParams = new URLSearchParams(window.location.search);
            const regionId = urlParams.get("region") === 'krr' ? 'krasnodar' : urlParams.get("region");
            if (regionId && this.regionStore.listRegions.find(region => region.id === regionId) !== undefined) {
                regionStore.setRegionId(regionId);
            }
        },
        goHome() {
            window.location = window.location;
        },
    }
};
</script>

<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(0.1rem);
}
</style>
