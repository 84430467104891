<template>
    <div class="space-y-4 px-4 md:px-6 pе-2">
        <div class="flex justify-between items-center">
            <div class="block text-left text-2xl pr-9">
                Оформление годовой программы
            </div>
            <div class="flex items-center hidden text-xl" v-if="programsStore.home">
                <span class="mr-2 text-base text-black/40">на дому</span>
            </div>
        </div>

        <div class="mt-9" v-if="docsList.length">
            <div class="mb-2 text-xl font-medium">Выберите ведущего врача</div>

            <div v-for="doc in docsList" :key="doc.id"
                 class="flex rounded-md mb-2 p-2 cursor-pointer border items-center"
                 :class="doc.id !== programsStore.leadDoc ? 'border-gray-300' : 'border-teal-400 border-2'">
                <div class="flex flex-wrap items-center justify-start w-full" @click="programsStore.leadDoc = doc.id">
                    <div class="mr-2 rounded-full w-12 h-12 max-w-12 overflow-hidden border-4 border-white">
                        <img v-if="doc?.attributes?.photo_mini?.data?.attributes?.url"
                             :src="`https://admin.fomin-kids.ru${doc.attributes.photo_mini.data.attributes.url}`"
                             :alt="`${doc.attributes.fname} ${doc.attributes.lname}`"
                             :title="`${doc.attributes.fname} ${doc.attributes.lname}`"
                             class="w-full h-full object-cover object-top" />
                        <img v-else :src="defaultPhoto" class="w-full h-full object-cover object-top" />
                    </div>
                    <div>{{ doc.attributes.lname }} {{ doc.attributes.fname }}</div>
                </div>
            </div>
        </div>

        <div class="py-3" v-if="programsStore.programCost">
            <div class="w-full p-4 bg-[#effcfa] rounded-2xl flex-col justify-center items-start gap-2 inline-flex">
                <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <div class="grow shrink basis-0 text-[#2d2a34] text-base font-['Circe'] leading-normal">
                        Стоимость:
                    </div>
                    <div class="text-right text-[#2d2a34] text-[24px] font-normal font-['Circe'] leading-10">
                        {{ numberWithSpaces(programsStore.totalSum) }} ₽
                    </div>
                </div>
            </div>


            <div
                class="w-full h-[120px] p-4 bg-[#f5f7fa] rounded-2xl border border-[#edeef2] flex-col justify-start items-start inline-flex mt-3">
                <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <div class="grow shrink basis-0 text-[#2d2a34] text-base  font-['Circe'] leading-normal">
                        Бонусы:
                    </div>
                    <div class="text-right text-[#2d2a34] text-[24px] font-normal font-['Circe'] leading-10">скидка
                        10%
                    </div>
                </div>
                <div class="self-stretch text-[#2d2a34] text-base font-normal font-['Circe'] leading-normal">На
                    амбулаторные услуги педиатрии в период действия программы.
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { createDateText, createTimeText } from "~/libs/date";
import { useSlots } from "~/store/slots";
import { usePrograms } from "~/store/programs";
import { useDocs } from "~/store/docs";

import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    Switch,
    SwitchGroup,
    SwitchLabel,
    TransitionChild,
    TransitionRoot
} from "@headlessui/vue";

import { XIcon } from "@heroicons/vue/outline";
import { numberWithSpaces, scrollTo } from "~/libs/ui";
import { useClinics } from "~/store/clinics";

export default {
    props: ["type"],
    setup(props) {
        const docsStore = useDocs();
        const slotsStore = useSlots();
        const programsStore = usePrograms();
        const clinicsStore = useClinics();

        return {
            docsStore,
            slotsStore,
            clinicsStore,
            createDateText,
            createTimeText,
            scrollTo,
            programsStore,
            numberWithSpaces
        };
    },

    data() {
        return {
            name: "Врач",
            clinicId: "",
            customProgramsByAges: null,
            listCustomProgramAges: null
        };
    },
    watch: {
        date() {
        }
    },
    components: {
        Dialog,
        DialogTitle,
        TransitionRoot,
        TransitionChild,
        DialogOverlay,
        Switch,
        SwitchGroup,
        SwitchLabel,
        XIcon
    },
    methods: {
        numberWithSpaces,
        declOfNum(number, words) {
            return words[
                number % 100 > 4 && number % 100 < 20
                    ? 2
                    : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
                ];
        }
    },
    computed: {
        clinicId() {
            return this.programsStore.clinicId;
        },
        customProgramsByAges() {
            return this.programsStore.customProgramsByAges;
        },
        listCustomProgramAges() {
            return this.programsStore.listCustomProgramAges;
        },
        docsList() {
            let docs = [];

            this.programsStore.filterCustomProgramsList.forEach(program => {
                docs = program.attributes.Combo.filter(combo => combo.isGeneral)[0].options.data.find(option => option.attributes.checked).attributes.docs;
            });

            return docs.map(doc => {
                const docTmp = this.docsStore.getDocById(doc?.id);
                // docTmp.minCost = doc.minCost;
                return docTmp;
            });
        },
        BasketData() {
            const result = {};


            this.programsStore.filterCustomProgramsList.forEach(program => {
                result.docs = program.attributes.Combo.filter(combo => combo.isGeneral)[0].options.data.find(option => option.attributes.checked).attributes.docs;
            });

            return result;

            for (let item in this.listCustomProgramAges) {
                for (let key in Object.keys(this.programsStore.customProgramsList)) {
                    if (this.programsStore.customProgramsList[key].attributes.ages.data[0].attributes.title === this.listCustomProgramAges[item].title) {
                        return {
                            title: this.listCustomProgramAges[item].title,
                            key,
                            docs: this.programsStore.customProgramsList[key].attributes.docs
                        };
                    }
                }
            }
            return null;
        },
        cart() {
            return Object.values(this.programsStore.cartDetails);
        },
        inCartTitles() {
            return Object.keys(this.programsStore.cartDetails);
        },
        cartJSON() {
            return JSON.stringify(this.programsStore.cartDetails);
        },
        counterText() {
            const text = this.declOfNum(this.programsStore.totalCount, [
                "позиция",
                "позиции",
                "позиций"
            ]);
            return `${this.programsStore.totalCount} ${text}`;
        }
    }
};
</script>
