<template>
    <div class="flex gap-24">
        <div>
            <ul class="flex flex-col gap-3">
                <li v-for="item in menu" :key="item.name">
                    <NuxtLink :to="item.href" class="text-xl font-medium">
                        {{ item.name }}
                    </NuxtLink>
                </li>
            </ul>
        </div>
        <div>
            <ul class="flex flex-col gap-3">
                <li v-for="item in menu2" :key="item.name">
                    <NuxtLink :to="item.href" class="text-xl font-medium">
                        {{ item.name }}
                    </NuxtLink>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterMenu',
    data() {
        return {
            menu: [
                { name: 'Врачи', href: '/docs' },
                { name: 'Услуги', href: '/services' },
                { name: 'Блог', href: '/blog' },
                { name: 'О нас', href: '/about' },
                { name: 'Контакты', href: '/contacts' },
            ],
            menu2: [
                { name: 'Приём педиатра', href: '/services/pediatr' },
                { name: 'Годовые программы', href: '/programs' },
                { name: 'Вакцинация', href: '/vacc' },
                { name: 'Выезд на дом', href: '/services/calling_a_pediatrician' },
                { name: 'Пациенту', href: '/info/preparation' },
            ],
        }
    }
}
</script>