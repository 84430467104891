<template>
  <div>
    <AppSelectClinics v-show="!(emptyShow && appStore.clinicCalendar === null)" v-if="appStore.type !== 'doc'"/>
    <div v-if="scheduleStore.getDates.length">
      <v-date-picker color="#72E0D8" class="vcalendar w-full" :available-dates="scheduleStore.getDates" :locale="{
        id: 'ru',
        firstDayOfWeek: 2,
        masks: { weekdays: 'WW' },
      }" v-model="appStore.date" is-expanded title-position="center" :highlight="false" is-required />
    </div>
    <div v-else-if="emptyShow">
      <AppStep5Empty />
    </div>
  </div>
</template>
<script>
import { useApp } from "~/store/app/app";
import { useSchedule } from "~/store/app/schedule";
import { useNav } from "~/store/app/nav";

export default {
  setup() {
    const appStore = useApp();
    const scheduleStore = useSchedule();
    const navStore = useNav();

    return {
      appStore,
      scheduleStore,
      navStore
    }
  },
  computed: {
    emptyShow() {
      return this.scheduleStore.scheduleFilter === null || Object.keys(this.scheduleStore.scheduleFilter).length === 0
    }
  },
  watch: {
    "scheduleStore.getDates": (val) => {
      // console.log(val);
    }
  }
}
</script>

<style lang="scss">
.vcalendar {
  border-color: #EFEDF2;
  font-family: "Circe", Arial, sans-serif;

  .is-today span {
    background-color: #F5F7FA;
    width: 32px;
    height: 32px;
  }

  .is-today .vc-highlights+span {
    background-color: #BBEFEC !important;
  }

  .vc-weekday {
    font-weight: 400;
    color: #9E9BA3;
    border-bottom: 1px solid #F8F7FA;
    margin-bottom: 10px;
  }

  .vc-title {
    color: #292B33;
    font-weight: 500;
    font-size: 18px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .is-not-in-month.on-bottom {
    display: none;
  }

  .vc-highlights {
    .vc-highlight {
      width: 32px;
      height: 32px;
      background-color: #BBEFEC;
    }
  }

  div.vc-highlights+span {
    color: black !important;
  }

  .vc-day {
    min-height: 36px;

    &-content {
      line-height: 32px;
      font-size: 14px !important;
      font-weight: 400;
      font-family: "Circe", Arial, sans-serif;
    }
  }
}
</style>