<template>
    <div class="bg-white">
        <div class="container mx-auto">
            <div class="flex first-line py-3">
                <div class="w-1/2 first-line-left flex items-center gap-4">
                    <div class="flex-shrink-0 h-auto cursor-pointer" @click="goHome">
                        <img :src="logo" alt="Клиника Фомина Дети" class="max-h-16 w-auto object-contain"
                            aria-label="Логотип" tabindex="0" />
                    </div>
                    <RegionClinic />
                    <div class="ml-2">
                        <a class="text-base font-medium whitespace-nowrap" href="tel:{{ phone }}">
                            {{ phone }}
                        </a>
                    </div>
                </div>
                <div class="w-1/2 first-line-right flex justify-end w-full items-center gap-10">
                    <div class="flex items-center gap-4">
                        <div class="font-medium opacity-50">Написать нам:</div>
                        <UIMessengersBlock :showLabel="false" />
                    </div>
                    <div class="flex gap-2 items-center">
                        <UIBtnPink text="Вызвать врача" size="10" @click="homeStore.openHome()"
                            v-if="regionStore?.regionInfo?.attributes?.home !== false" />
                        <UIBtnGreen text="Записаться" size="10" @click="appStore.openApp('all')" />
                    </div>
                </div>
            </div>

            <div>
                <div class="w-full h-[1px] relative bg-[#f5f7fa]"></div>
            </div>

            <div class="second-line py-3 pl-6">
                <div class="flex w-full justify-between items-center">
                    <div class="w-1/10">
                        <PopoverGroup as="nav" class="flex space-x-3 lg:space-x-3 xl:space-x-9 items-center">
                            <Popover class="relative" v-slot="{ open }">
                                <PopoverButton
                                    class="group  inline-flex items-center text-sm uppercase focus:outline-none lg:min-w-[56px]"
                                    :class="[open ? 'text-gray-900' : 'text-black/80']">
                                    <span>Услуги</span>
                                    <ChevronDownIcon class="ml-0.5 w-2 lg:h-4 h-2 lg:w-4 group-hover:text-gray-600"
                                        :class="[open ? 'text-gray-600' : 'text-black/80']" aria-hidden="true" />
                                </PopoverButton>

                                <transition enter-active-class="transition ease-out duration-300"
                                    enter-from-class="opacity-0 translate-y-1"
                                    enter-to-class="opacity-100 translate-y-0"
                                    leave-active-class="transition ease-in duration-200"
                                    leave-from-class="opacity-100 translate-y-0"
                                    leave-to-class="opacity-0 translate-y-1">
                                    <PopoverPanel
                                        class="fixed z-50 top-[calc(var(--header-height,80px))] left-0 right-0 w-full">
                                        <div
                                            class="w-[90%] bg-white rounded-2xl shadow-[0px_4px_16px_0px_rgba(0,0,0,0.10)]   max-w-7xl mx-auto p-12">
                                            <div class="grid grid-cols-4 items-start">
                                                <div class="grid grid-cols-1 gap-5">
                                                    <NuxtLink :to="link.url" v-for="link in subMenuNav" :key="link.url"
                                                        class="text-base xl:text-2xl hover:text-pink-500 transition duration-150">
                                                        <PopoverButton>{{ link.title }}</PopoverButton>
                                                    </NuxtLink>
                                                </div>
                                                <div class="col-span-3">
                                                    <div class="grid grid-cols-3 gap-6">
                                                        <NuxtLink :to="link?.url ? `/services/${link.url}` : `/develop`"
                                                            v-for="link in subMenuList" :key="link.url"
                                                            class="hover:text-pink-500 text-base transition duration-150 whitespace-nowrap">
                                                            <PopoverButton>{{ link.title }}</PopoverButton>
                                                        </NuxtLink>
                                                    </div>
                                                </div>
                                            </div>
                                            <PopoverButton
                                                class="mt-12 w-full h-12 px-6 py-3 rounded-[28px] border border-[#edeef2] inline-flex justify-center items-center gap-2 cursor-pointer"
                                                @click="$router.push('/services');">
                                                Все услуги
                                            </PopoverButton>
                                        </div>
                                    </PopoverPanel>
                                </transition>
                            </Popover>
                        </PopoverGroup>
                    </div>
                    <div class="w-8/10">
                        <div class="flex justify-between items-center gap-20">
                            <div v-for="itemMenu in menu">
                                <NuxtLink :to="itemMenu.link" class="text-sm font-medium uppercase">
                                    {{ itemMenu.name }}
                                </NuxtLink>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/10">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="hidden">
                            <rect width="12" height="12" rx="6" transform="matrix(-1 0 0 1 17 4)" stroke="#292B33"
                                stroke-width="1.5" stroke-linecap="round" />
                            <path d="M15.5 14.5L19 18" stroke="#292B33" stroke-width="1.5" stroke-linecap="round" />
                        </svg>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRegion } from "~/store/app/region";
import { useServices } from "~/store/services";
import { useClinics } from "~/store/clinics";
import { ChevronDownIcon } from '@heroicons/vue/solid';
import logo from '~/public/img/fomin.svg';
import { useHome } from '~/store/home';
import { useApp } from '~/store/app/app';

import {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from "@headlessui/vue";

const subMenuNav = [
    {
        title: "Приём педиатра",
        url: "/services/pediatr",
        active: false,
    },
    {
        title: "Вызов педиатра на дом",
        url: "/services/calling_a_pediatrician",
        active: false,
    },
    {
        title: "Годовые программы",
        url: "/programs",
        active: false,
    },
    {
        title: "Вакцинация",
        url: "/vacc",
        active: false,
    },
    {
        title: "Чекапы",
        url: "/services/checkup",
        active: false,
    },
];

export default {
    name: 'HeaderNew',
    components: {
        Popover,
        PopoverButton,
        PopoverGroup,
        PopoverPanel,
        ChevronDownIcon,
        Container: resolveComponent('Container'),
    },
    async setup() {
        const open = ref(false);
        const regionStore = useRegion();
        const servicesStore = useServices();
        const clinicsStore = useClinics();
        const homeStore = useHome();
        const appStore = useApp();


        if (!servicesStore.listServices.length) {
            await servicesStore.fetchServices();
        }

        if (!servicesStore.listCategories.length) {
            await servicesStore.fetchCategory();
        }
        const listCategories = servicesStore.listCategories;

        const menu = ref([
            {
                name: 'Врачи',
                link: '/docs'
            },
            {
                name: 'Цены',
                link: '/price'
            },
            {
                name: 'Вакцинация',
                link: '/vacc'
            },
            {
                name: 'Программы',
                link: '/programs'
            },
            {
                name: 'Клиники',
                link: '/contacts'
            },
            {
                name: 'Блог',
                link: '/blog'
            },
            {
                name: 'О нас',
                link: '/about'
            }
        ]);

        return {
            homeStore,
            appStore,
            logo,
            open,
            regionStore,
            listCategories,
            subMenuNav,
            clinicsStore,
            subMenuList: computed(() => {
                try {
                    return servicesStore.listServices
                        .filter((service) => {
                            try {
                                const serviceEx = servicesStore.getServiceById(+service.id);
                                return (
                                    service.attributes.menu &&
                                    serviceEx?.attributes?.clinics?.data &&
                                    serviceEx?.attributes?.clinics?.data?.findIndex(
                                        (clinic) => clinic.attributes.clinicId === clinicsStore.clinicId
                                    ) !== -1
                                );
                            } catch (e) {
                                console.error("Error filtering service:", e);
                                return false;
                            }
                        })
                        .map((service) => ({
                            title: service.attributes.title,
                            url: service.attributes.link,
                        }));
                } catch (e) {
                    console.error("Error in subMenuList computed:", e);
                    return fallbackSubMenuList.value;
                }
            }),
            menu,
        }
    },
    mounted() {
        document.documentElement.style.setProperty('--header-height', `${this.$el.offsetHeight}px`);

        window.addEventListener('resize', () => {
            document.documentElement.style.setProperty('--header-height', `${this.$el.offsetHeight}px`);
        });
    },
    methods: {
        goHome() {
            this.$router.push('/');
        }
    },
    computed: {
        phone() {
            return this.regionStore?.regionInfo?.attributes?.phone ? this.regionStore.regionInfo.attributes.phone : this.regionStore.phone_rf;
        }
    }
}
</script>

<style>
:root {
    --header-height: 80px;
}
</style>
