<template>
    <div class="space-y-4 px-4 md:px-6 py-2">
        <div class="flex justify-between items-center">
            <div class="block text-left text-2xl pr-9">
                Оформление годовой программы «{{ programsStore.program }}»
            </div>
            <div class="flex items-center text-xl hidden" v-if="programsStore.home">
                <span class="mr-2 text-base text-black/40">на дому</span>
            </div>
        </div>

        <div class="mt-9" v-if="docsList.length">
            <div class="mb-2 text-xl font-medium">Выберите ведущего врача</div>

            <div v-for="doc in docsList" :key="doc.id"
                 class="flex rounded-md mb-2 p-2 cursor-pointer border items-center"
                 :class="doc.id !== programsStore.leadDoc ? 'border-gray-300' : 'border-teal-400 border-2'">
                <div class="flex flex-wrap items-center justify-start w-full" @click="selectDoc(doc.id)">
                    <div class="mr-2 rounded-full w-12 h-12 max-w-12 overflow-hidden border-4 border-white">
                        <img v-if="doc?.attributes?.photo_mini?.data?.attributes?.url"
                             :src="`https://admin.fomin-kids.ru${doc.attributes.photo_mini.data.attributes.url}`"
                             :alt="`${doc.attributes.fname} ${doc.attributes.lname}`"
                             :title="`${doc.attributes.fname} ${doc.attributes.lname}`"
                             class="w-full h-full object-cover object-top" />
                        <img v-else :src="defaultPhoto" class="w-full h-full object-cover object-top" />
                    </div>
                    <div>{{ doc.attributes.lname }} {{ doc.attributes.fname }}</div>
                </div>
                <div v-if="doc.price" class="whitespace-nowrap">{{ programsStore.home ? "от" : "" }} {{
                        numberWithSpaces(doc.price) }} Р
                </div>
                <div v-else class="whitespace-nowrap">{{ programsStore.home ? "от" : "" }} {{
                        programsStore.programCostDefault ? numberWithSpaces(programsStore.programCostDefault) : "" }} ₽
                </div>
            </div>
        </div>

        <div class="pt-3" v-if="docsList.length && programsStore.programCost" id="online-chat">
            <div class="mb-2 text-xl font-medium">Онлайн чат с врачом</div>

            <div
                class="h-14 w-full p-4 bg-[#EDEFF2] rounded-2xl border border-[#edeef2] justify-start items-center gap-4 inline-flex mb-2"
                @click="setTypeOnline(0)">
                <div class="grow shrink basis-0 text-black text-base font-normal font-['Circe'] leading-normal">Дежурный
                    педиатр 24/7
                </div>

                <div class="opacity-50 text-black text-base font-normal font-['Circe'] leading-normal">включено</div>


                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="16" height="16" rx="4" fill="#C9CBCF" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12.773 4.55547C12.9136 4.69612 12.9926 4.88685 12.9926 5.08572C12.9926 5.2846 12.9136 5.47533 12.773 5.61597L7.15151 11.2375C7.07722 11.3118 6.98902 11.3707 6.89195 11.4109C6.79488 11.4512 6.69084 11.4719 6.58576 11.4719C6.48069 11.4719 6.37665 11.4512 6.27957 11.4109C6.1825 11.3707 6.0943 11.3118 6.02001 11.2375L3.22701 8.44497C3.15538 8.37579 3.09824 8.29303 3.05894 8.20153C3.01963 8.11002 2.99894 8.01161 2.99808 7.91202C2.99721 7.81244 3.01619 7.71368 3.0539 7.62151C3.09161 7.52933 3.1473 7.4456 3.21772 7.37518C3.28814 7.30476 3.37188 7.24907 3.46405 7.21136C3.55622 7.17365 3.65498 7.15467 3.75456 7.15553C3.85415 7.1564 3.95256 7.17709 4.04407 7.2164C4.13557 7.2557 4.21833 7.31284 4.28751 7.38447L6.58551 9.68247L11.712 4.55547C11.7817 4.48578 11.8644 4.43049 11.9554 4.39277C12.0464 4.35505 12.144 4.33563 12.2425 4.33563C12.341 4.33563 12.4386 4.35505 12.5296 4.39277C12.6207 4.43049 12.7034 4.48578 12.773 4.55547Z"
                          fill="#2D2A34" />
                </svg>
            </div>

            <div v-if="programsStore?.leadDoc">
                <div
                    class="h-28 p-4 bg-white rounded-2xl border flex-col justify-start items-start gap-2 inline-flex cursor-pointer"
                    :class="{'border-[#72e0d8] border-2': programsStore.leadOnline, 'border-[#edeef2]': !programsStore.leadOnline}"
                    @click="programsStore.leadOnline = !programsStore.leadOnline">
                    <div class="self-stretch justify-start items-center gap-2 inline-flex">
                        <div class="w-8 h-8 rounded-[100px] overflow-auto">
                            <img v-if="leadDoc?.attributes?.photo_mini?.data?.attributes?.url"
                                 :src="`https://admin.fomin-kids.ru${leadDoc.attributes.photo_mini.data.attributes.url}`"
                                 :alt="`${leadDoc.attributes.fname} ${leadDoc.attributes.lname}`"
                                 :title="`${leadDoc.attributes.fname} ${leadDoc.attributes.lname}`"
                                 class="w-full h-full object-cover object-top" />
                            <img v-else :src="defaultPhoto" class="w-full h-full object-cover object-top" />
                        </div>

                        <div
                            class="grow shrink basis-0 h-6 text-[#2d2a34] text-base font-['Circe'] leading-normal">
                            {{ leadDoc.attributes.lname }} {{ leadDoc.attributes.fname }}
                        </div>
                        <div data-svg-wrapper class="relative">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" v-if="!programsStore.leadOnline">
                                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#C9CBCF" />
                            </svg>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" v-else>
                                <rect width="16" height="16" rx="4" fill="#C0F0EA" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12.773 4.55547C12.9136 4.69612 12.9926 4.88685 12.9926 5.08572C12.9926 5.2846 12.9136 5.47533 12.773 5.61597L7.15151 11.2375C7.07722 11.3118 6.98902 11.3707 6.89195 11.4109C6.79488 11.4512 6.69084 11.4719 6.58576 11.4719C6.48069 11.4719 6.37665 11.4512 6.27957 11.4109C6.1825 11.3707 6.0943 11.3118 6.02001 11.2375L3.22701 8.44497C3.15538 8.37579 3.09824 8.29303 3.05894 8.20153C3.01963 8.11002 2.99894 8.01161 2.99808 7.91202C2.99721 7.81244 3.01619 7.71368 3.0539 7.62151C3.09161 7.52933 3.1473 7.4456 3.21772 7.37518C3.28814 7.30476 3.37188 7.24907 3.46405 7.21136C3.55622 7.17365 3.65498 7.15467 3.75456 7.15553C3.85415 7.1564 3.95256 7.17709 4.04407 7.2164C4.13557 7.2557 4.21833 7.31284 4.28751 7.38447L6.58551 9.68247L11.712 4.55547C11.7817 4.48578 11.8644 4.43049 11.9554 4.39277C12.0464 4.35505 12.144 4.33563 12.2425 4.33563C12.341 4.33563 12.4386 4.35505 12.5296 4.39277C12.6207 4.43049 12.7034 4.48578 12.773 4.55547Z"
                                      fill="#2D2A34" />
                            </svg>

                        </div>
                    </div>
                    <div class="self-stretch text-[#2d2a34] text-base font-normal font-['Circe'] leading-tight">Добавить
                        чат с моим ведущим педиатром со скидкой 15%.
                    </div>
                </div>
            </div>
        </div>


        <div class="py-3" v-if="programsStore.programCost">
            <div class="w-full p-4 bg-[#effcfa] rounded-2xl flex-col justify-center items-start gap-2 inline-flex">
                <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <div class="grow shrink basis-0 text-[#2d2a34] text-base font-['Circe'] leading-normal">
                        Стоимость:
                    </div>
                    <div class="text-right text-[#2d2a34] text-[24px] font-normal font-['Circe'] leading-10">{{
                            resultPrice }} Р
                    </div>
                </div>
                <div class="self-stretch h-0.5 bg-[#daf2ef] rounded-sm" v-if="programsStore.leadOnline"></div>
                <div class="self-stretch text-[#2d2a34] text-base font-normal font-['Circe'] leading-tight"
                     v-if="programsStore.leadOnline">Стоимость с учётом онлайн поддержки ведущего педиатра уточнит
                    менеджер.
                </div>
            </div>

            <div
                class="w-full h-[120px] p-4 bg-[#f5f7fa] rounded-2xl border border-[#edeef2] flex-col justify-start items-start inline-flex mt-3">
                <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <div class="grow shrink basis-0 text-[#2d2a34] text-base font-['Circe'] leading-normal">
                        Бонусы:
                    </div>
                    <div class="text-right text-[#2d2a34] text-[24px] font-normal font-['Circe'] leading-10">скидка
                        10%
                    </div>
                </div>
                <div class="self-stretch text-[#2d2a34] text-base font-normal font-['Circe'] leading-normal">На
                    амбулаторные услуги педиатрии в период действия программы.
                </div>
            </div>

            <div class="bg-[#FBE7F280] rounded-2xl px-4 py-2 mt-4" v-if="programsStore.home">
                Итоговая стоимость программы зависит от адреса проживания
            </div>
        </div>

    </div>
</template>

<script>
import defaultPhoto from "@/assets/img/docs/placeholders/woman_1.png";
import { createDateText, createTimeText } from "~/libs/date";
import { useSlots } from "~/store/slots";
import { usePrograms } from "~/store/programs";
import { useDocs } from "~/store/docs";

import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    Switch,
    SwitchGroup,
    SwitchLabel,
    TransitionChild,
    TransitionRoot
} from "@headlessui/vue";

import { XIcon } from "@heroicons/vue/outline";
import { numberWithSpaces, scrollTo } from "~/libs/ui";
import { useClinics } from "~/store/clinics";

export default {
    props: ["type", "docs"],
    setup() {
        const docsStore = useDocs();
        const slotsStore = useSlots();
        const programsStore = usePrograms();
        const clinicsStore = useClinics();

        return {
            numberWithSpaces,
            docsStore,
            slotsStore,
            clinicsStore,
            createDateText,
            createTimeText,
            scrollTo,
            defaultPhoto,
            programsStore
        };
    },

    data() {
        return {
            name: "Врач",
            clinicId: "",
            customProgramsByAges: null,
            listCustomProgramAges: null
        };
    },
    watch: {
        date() {
        }
    },
    components: {
        Dialog,
        DialogTitle,
        TransitionRoot,
        TransitionChild,
        DialogOverlay,
        Switch,
        SwitchGroup,
        SwitchLabel,
        XIcon
    },
    methods: {
        setTypeOnline(type) {
            this.programsStore.typeOnline = type;
        },
        selectDoc(id) {
            this.programsStore.leadDoc = id;
            const price = this.docsList.find(doc => doc.id === id).price;
            if (price) {
                this.programsStore.programCost = price;
            } else {
                this.programsStore.programCost = this.programsStore.programCostDefault;
            }

            //scroll to online-chat minus 15px
            const onlineChat = document.getElementById("online-chat");
            if (onlineChat) {
                onlineChat.scrollIntoView({ behavior: "smooth" });
            }
        },
        declOfNum(number, words) {
            return words[
                number % 100 > 4 && number % 100 < 20
                    ? 2
                    : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
                ];
        }
    },
    async mounted() {
        this.clinicId = this.clinicsStore.clinicId;

        this.customProgramsByAges = this.programsStore.customProgramsByAges;
        this.listCustomProgramAges = this.programsStore.listCustomProgramAges;

        this.selectedItem = this.listCustomProgramAges[this.selectedIndex];
    },
    computed: {
        leadDoc() {
            return this.docsStore.getDocById(this.programsStore.leadDoc);
        },
        docsList() {
            return this.programsStore.programDocs.filter(doc => {
                return this.docsStore.docsByClinicId(this.programsStore.clinicId).findIndex(docTmp => {
                    return doc.id === docTmp.id;
                }) !== -1;
            }).reverse();
        },
        cart() {
            return Object.values(this.programsStore.cartDetails);
        },
        resultPrice() {
            if (!this.programsStore.programCost) return;
            if (this.programsStore.leadOnline) {
                return "от " + numberWithSpaces(this.programsStore.programCost);
            } else {
                return numberWithSpaces(this.programsStore.programCost);
            }
        },
        inCartTitles() {
            return Object.keys(this.programsStore.cartDetails);
        },
        cartJSON() {
            return JSON.stringify(this.programsStore.cartDetails);
        },
        counterText() {
            const text = this.declOfNum(this.programsStore.totalCount, [
                "позиция",
                "позиции",
                "позиций"
            ]);
            return `${this.programsStore.totalCount} ${text}`;
        }
    }
};
</script>
