<template>
    <template v-if="loader">
        <AppLoader text="Загрузка графика" />
    </template>
    <template v-else>
        <div class="wrap_calendar">
            <AppStep5Calendar class="mb-8" />
            <AppStep5Times v-if="appStore.date" />
        </div>
    </template>
</template>
<script>
import { useApp } from "~/store/app/app";
import { useSchedule } from "~/store/app/schedule";
import { scrollTo } from "~/libs/ui";

export default {
    data() {
        return {
            loader: false,
            firstScroll: false,
        };
    },
    async setup() {
        const appStore = useApp();
        const scheduleStore = useSchedule();

        return {
            appStore,
            scheduleStore
        };
    },
    async mounted() {
        try {
            if (this.appStore.hotListGroups.length === 1) {
                this.appStore.groupId = this.appStore.hotListGroups[0].id;
            }
            this.loader = true;
            this.appStore.clearApp(4);
            await this.appStore.createCalendar();

            this.firstScroll = true;
            this.appStore.date = this.scheduleStore.getDates[0];

            this.loader = false;
        } catch (e) {
            console.log(e);
        }
    },
    watch: {
        "appStore.date": function (val) {
            if (this.firstScroll) {
                this.firstScroll = false;
            } else {
                scrollTo("times", "wrap_calendar");
            }
        }
    }
};
</script>
