<template>
    <svg width="61" height="44" viewBox="0 0 61 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27.8205 18.8285C26.8297 14.8113 25.023 11.0486 23.0997 7.3865C22.6546 6.54385 22.1884 5.68001 21.4625 5.05995C20.2651 4.03711 18.5272 3.84102 16.996 4.1961C15.4595 4.54588 14.0872 5.38323 12.7679 6.24177C10.1028 7.97476 4.89454 11.7322 4.89454 11.7322C2.54207 14.2072 0.798908 17.3181 0.21079 20.6834C-0.377328 24.0487 0.258475 27.6683 2.20297 30.4772C3.23615 31.9611 4.59783 33.1959 5.62041 34.6904C6.55822 36.0683 7.19932 37.6582 8.35966 38.8506C9.52001 40.0431 11.4857 40.7267 12.8527 39.7781C14.8237 38.4161 17.4887 38.9672 19.8412 38.5168C23.5183 37.8119 26.2946 34.5791 27.5503 31.0495C28.9385 27.1649 28.806 22.8456 27.8205 18.8285Z"
            fill="#DAF2EF" />
        <path
            d="M59.2504 9.68655C58.1643 5.80189 55.1866 2.76518 51.536 1.16468C48.4206 -0.202632 43.0321 -1.16187 41.5433 3.04607C40.987 4.61477 40.8333 5.89729 39.318 6.94662C32.4725 11.6898 34.3269 19.4379 35.8953 26.3063C36.4569 28.7548 37.1033 31.3622 38.9524 33.0581C40.8969 34.8388 43.7951 35.2045 46.3913 34.7699C51.6155 33.8848 56.0979 30.0373 58.3285 25.2305C60.5644 20.4184 60.6757 14.7901 59.2504 9.68655Z"
            fill="#FBE7F2" />
        <path
            d="M31.3757 43.0956C19.7935 43.0956 10.373 33.6728 10.373 22.0877C10.373 10.508 19.7935 1.07984 31.3757 1.07984C42.9579 1.07984 52.3784 10.5027 52.3784 22.0877C52.3784 33.6728 42.9579 43.0956 31.3757 43.0956ZM31.3757 1.93839C20.265 1.93839 11.226 10.9796 11.226 22.093C11.226 33.2064 20.265 42.2477 31.3757 42.2477C42.4863 42.2477 51.5253 33.2064 51.5253 22.093C51.5253 10.9743 42.4863 1.93839 31.3757 1.93839Z"
            fill="#2D2A34" />
        <path
            d="M31.3758 39.7781C31.0791 39.7781 30.8141 39.6297 30.6552 39.3806L29.2405 37.1176C29.0763 36.858 29.071 36.5241 29.2193 36.2538C29.3677 35.9835 29.6538 35.8192 29.9611 35.8192H32.7851C33.0924 35.8192 33.3785 35.9888 33.5269 36.2538C33.6753 36.5241 33.67 36.8527 33.5057 37.1176L32.091 39.3806C31.9427 39.6297 31.6725 39.7781 31.3758 39.7781ZM32.7851 36.6672L29.9611 36.6725L31.3811 38.9248L32.7851 36.6672Z"
            fill="#2D2A34" />
        <path
            d="M45.517 31.5582C44.8017 31.5582 44.2242 30.9752 44.2242 30.2651C44.2242 29.5496 44.8017 28.972 45.517 28.972C46.2323 28.972 46.8098 29.5549 46.8098 30.2651C46.8098 30.9805 46.2323 31.5582 45.517 31.5582ZM45.517 29.8252C45.2733 29.8252 45.0772 30.0213 45.0772 30.2651C45.0772 30.5089 45.2733 30.705 45.517 30.705C45.7607 30.705 45.9568 30.5089 45.9568 30.2651C45.9568 30.0213 45.7607 29.8252 45.517 29.8252Z"
            fill="#2D2A34" />
        <path
            d="M17.2345 31.5582C16.5192 31.5582 15.9417 30.9752 15.9417 30.2651C15.9417 29.5496 16.5192 28.972 17.2345 28.972C17.9497 28.972 18.5273 29.5549 18.5273 30.2651C18.5273 30.9805 17.9497 31.5582 17.2345 31.5582ZM17.2345 29.8252C16.9907 29.8252 16.7947 30.0213 16.7947 30.2651C16.7947 30.5089 16.9907 30.705 17.2345 30.705C17.4782 30.705 17.6742 30.5089 17.6742 30.2651C17.6742 30.0213 17.4782 29.8252 17.2345 29.8252Z"
            fill="#2D2A34" />
    </svg>
</template>
