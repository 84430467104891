<template>
    <div id="top" class="pb-4"></div>
    <div class="mb-4">
        <div class="font-light leading-5 text-sm text-black mb-1">
            Ваше имя
        </div>
        <input v-model="orderStore.name" type="text"
               class="mobile-no-focus bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
               placeholder="Введите имя"
               readonly
               @focus="handleFocus"
        >
    </div>
    <div class="mb-4">
        <div class="font-light leading-5 text-sm text-black mb-1">
            Телефон
        </div>
        <input class="mobile-no-focus bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
               v-maska="'+7 (###) ###-##-##'"
               placeholder="+7 (___) __-__-__"
               type="text"
               name="phone"
               id="phone"
               v-model="orderStore.phone"
               readonly
               @focus="handleFocus"
        >
    </div>
    <div class="mb-4" v-if="programStore.home">
        <div class="font-light leading-5 text-sm text-black mb-1">
            Адрес
        </div>
        <input v-model="orderStore.address" type="text"
               class="mobile-no-focus bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
               placeholder="Введите адрес"
               readonly
               @focus="handleFocus"
        >
    </div>
    <div class="mb-4">
        <div class="font-light leading-5 text-sm text-black mb-1">
            Комментарий
        </div>
        <textarea
                v-model="orderStore.comment"
                class="mobile-no-focus bg-gray-10 p-4 w-full rounded-2xl font-light leading-5 text-black border"
                placeholder=""
                readonly
                @focus="handleFocus"
        ></textarea>
    </div>
    <div class="mt-3">
        <AppUiBtn text="Отправить" @click="send"/>
    </div>
</template>

<script>
import {useApp} from "~/store/app/app";
import {useOrder} from "~/store/app/order";
import {usePatient} from "~/store/app/patient";
import {useStat} from "~/store/stat";
import {usePrograms} from "~/store/programs";
import {ref} from 'vue';

export default {
    setup() {
        const appStore = useApp();
        const orderStore = useOrder();
        const patientStore = usePatient();
        const programStore = usePrograms();

        const handleFocus = (event) => {
            event.target.removeAttribute('readonly');
        };

        orderStore.name = patientStore?.patient?.name?.length ? patientStore.patient.name : "";
        orderStore.phone = patientStore?.patient?.phone?.length ? patientStore.patient.phone : "";

        return {
            programStore,
            appStore,
            orderStore,
            patientStore,
            handleFocus
        }
    },
    methods: {
        async send() {
            this.programStore.status = true;
            await this.orderStore.sendProgram();

            //
            // if (this.orderStore.comment.indexOf('Пустой') !== -1) {
            //     const statStore = useStat();
            //     statStore.goal('empty');
            //     statStore.callTouch({
            //         fio: this.orderStore.name,
            //         phone: this.orderStore.phone,
            //         subject: 'Заявка пустой график',
            //         tag: 'order',
            //     })
            // }
            // this.orderStore.status = true;
            // await this.orderStore.send();
        }
    }
}
</script>

<style scoped>
.mobile-no-focus {
    -webkit-user-select: text;
    user-select: text;
}

@media (max-width: 768px) {
    .mobile-no-focus {
        font-size: 16px; /* Предотвращает масштабирование на iOS */
    }
}
</style>
