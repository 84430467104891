<template>
    <div>
        <div class="hidden md:block">
            <HeaderDesktop />
        </div>
        <div class="block md:hidden">
            <HeaderMobile />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderNew',
}
</script>