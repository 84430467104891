<template>
    <div class="flex items-center home bg-gray-50 justify-center" id="map">
        <div class="w-full relative map-container" v-if="showMap">
            <FixedMarker :active="mapState.isInteracting" :loading="mapState.isAddressLoading" />

            <div class="absolute right-2 bottom-20 z-10 flex flex-col gap-2">
                <button
                    @click="handleGeolocation"
                    class="bg-white w-10 h-10 rounded-lg shadow-md hover:bg-gray-50 focus:outline-none flex items-center justify-center"
                    :class="{ 'text-teal-500': isGeolocationActive }"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                </button>

                <div class="bg-white rounded-lg shadow-md">
                    <button
                        @click="handleZoom(1)"
                        class="w-10 h-10 hover:bg-gray-50 focus:outline-none border-b border-gray-200 flex items-center justify-center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                    </button>
                    <button
                        @click="handleZoom(-1)"
                        class="w-10 h-10 hover:bg-gray-50 focus:outline-none flex items-center justify-center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                        </svg>
                    </button>
                </div>
            </div>

            <div v-if="isLoading" class="absolute top-2 right-2 z-10 bg-white px-3 py-1 rounded-full shadow-md">
            </div>

            <yandex-map ref="mapRef" v-model="map" :settings="mapSettings" width="100%" height="40vh">
                <yandex-map-default-scheme-layer />
                <yandex-map-default-features-layer />

                <yandex-map-feature v-for="(polygon, idx) in polygons" :key="`polygon-${idx}`" :settings="{
                    geometry: {
                        type: 'Polygon',
                        coordinates: polygon.coords
                    },
                    properties: {
                        hintContent: polygon.title
                    },
                    style: {
                        stroke: [
                            { color: polygon.color, width: 2 }
                        ],
                        fill: polygon.color
                    }
                }" />

                <yandex-map-listener :settings="{
                    onUpdate: createEventHandler('map', 'update'),
                    // onResize: createEvent('map', 'resize'),
                    // onClick: createEvent('dom', 'click'),
                    // onFastClick: createEvent('dom', 'fastClick'),
                    // onDblClick: createEvent('dom', 'dblClick'),
                    // onContextMenu: createEvent('dom', 'contextMenu'),
                    // onRightDblClick: createEvent('dom', 'rightDblClick'),
                    // onMouseMove: createEvent('dom', 'mouseMove'),
                    // onMouseEnter: createEvent('dom', 'mouseEnter'),
                    // onMouseLeave: createEvent('dom', 'mouseLeave'),
                    // onMouseDown: createEvent('dom', 'mouseDown'),
                    // onMouseUp: createEvent('dom', 'mouseUp'),

                    onActionStart: createEventHandler('behavior', true),
                    onActionEnd: createEventHandler('behavior', false),
                }" />
            </yandex-map>
        </div>
        <div v-else class="animate-pulse block w-full z-10 h-14 px-2 border bg-gray-50 rounded-md pr-10 h-[60vh] justify-center items-center flex">
            <Loader/>
        </div>

    </div>

</template>

<script>
import {
    YandexMap,
    YandexMapDefaultSchemeLayer,
    YandexMapDefaultFeaturesLayer,
    YandexMapFeature,
    YandexMapListener
} from "vue-yandex-maps";
import FixedMarker from "~/components/Home/Step/1/FixedMarker.vue";
import { useClinics } from "@/store/clinics";
import { useHome } from "@/store/home";
import { useUi } from "@/store/ui";
import { reactive, ref } from "vue";

export default {
    name: "Map",
    components: {
        YandexMap,
        YandexMapDefaultSchemeLayer,
        YandexMapDefaultFeaturesLayer,
        YandexMapFeature,
        YandexMapListener,
        FixedMarker
    },

    data() {
        return {
            YANDEX_API_KEY: "4f9fce41-93d6-44f6-be5a-a8145eb23ca7",
            MAP_CONFIG: {
                MIN_ZOOM: 8,
                MAX_ZOOM: 18,
                DEFAULT_ZOOM: 9,
                DETAILED_ZOOM: 16
            },
            TIMEOUTS: {
                ZOOM_ANIMATION: 1000,
                ADDRESS_DETECTION: 3000
            },
            showMap: false,
            map: null,
            polygons: [],
            mapSettings: {
                location: {
                    center: [],
                    zoom: 9
                },
                behaviors: ["drag", "scrollZoom", "dblClick", "mouseRotate", "mouseTilt"]
            },
            mapState: {
                isLoading: false,
                isInteracting: false,
                isAddressLoading: false,
                isGeolocationActive: false
            },
            geocodeTimeout: null,
            rectangleBounds: [],
        };
    },

    setup() {
        const homeStore = useHome();
        const clinicStore = useClinics();
        const uiStore = useUi();

        const mapCenter = ref(null);
        const mapEvents = reactive({
            map: { update: false },
            behavior: {
                scrollZoom: false,
                drag: false,
                mouseRotate: false,
                mouseTilt: false
            }
        });

        return {
            homeStore,
            clinicStore,
            uiStore,
            mapCenter,
            mapEvents
        };
    },

    computed: {
        initialMapConfig() {
            const coords = JSON.parse(JSON.stringify(this.clinicStore.clinicCoords)).reverse();
            const zoom = this.clinicStore.clinic.attributes?.mapzoom || this.MAP_CONFIG.DEFAULT_ZOOM;

            return { coords, zoom };
        }
    },

    methods: {
        updateDebugBounds() {
            this.bounds = this.homeStore.boundedCompile(this.polygons);
            this.rectangleBounds = this.homeStore.getRectangleVertices(
                this.bounds[0],
                this.bounds[1]
            );

            this.polygons.push({
                title: "bounds",
                color: "rgba(211,1,110,0.25)",
                coords: [[...this.rectangleBounds]]
            });
        },
        createEventHandler(category, type) {
            const eventState = this.mapEvents[category];

            if (typeof type !== "boolean") {
                const endEvent = this.debounce(() => {
                    eventState[type] = false;
                }, 250);

                return (object) => {
                    if (object?.location?.center) {
                        this.mapCenter = object.location.center;
                    }
                    eventState[type] = true;
                    endEvent();
                };
            }

            return (object) => {
                if (!(object.type in this.mapEvents.behavior)) return;
                eventState[object.type] = type;
            };
        },

        debounce(func, delay) {
            let timeoutId;
            return (...args) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => func.apply(this, args), delay);
            };
        },

        async handleMapGeocoding(coords) {
            try {
                this.mapState.isAddressLoading = true;
                const [longitude, latitude] = coords;
                const response = await fetch(
                    `https://geocode-maps.yandex.ru/1.x/?apikey=${this.YANDEX_API_KEY}&format=json&geocode=${longitude},${latitude}`
                );
                const data = await response.json();
                await this.processGeocodeResponse(data);
            } catch (error) {
                console.error("Geocoding error:", error);
            } finally {
                this.mapState.isAddressLoading = false;
                this.mapState.isInteracting = false;
            }
        },

        processGeocodeResponse(data) {
            const geoObject = data.response.GeoObjectCollection.featureMember[0]?.GeoObject;
            if (!geoObject) return;

            const addressDetails = this.extractAddressDetails(geoObject);
            this.updateHomeStore(addressDetails);
        },

        extractAddressDetails(geoObject) {
            const metadata = geoObject.metaDataProperty.GeocoderMetaData;
            const addressDetails = metadata.AddressDetails.Country;
            const locality = addressDetails.AdministrativeArea?.SubAdministrativeArea?.Locality;
            const thoroughfare = locality?.Thoroughfare;

            return {
                street: thoroughfare?.ThoroughfareName || "",
                house: thoroughfare?.Premise?.PremiseNumber || "",
                city: locality?.LocalityName,
                country: addressDetails.CountryName,
                fullText: metadata.text.replace(/^Россия,\s*/, "")
            };
        },

        handleZoom(delta) {
            const { MIN_ZOOM, MAX_ZOOM } = this.MAP_CONFIG;
            const newZoom = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, this.mapSettings.location.zoom + delta));
            this.mapSettings.location.zoom = newZoom;
        },

        async handleGeolocation() {
            if (!navigator.geolocation) {
                console.error("Geolocation is not supported");
                return;
            }

            try {
                this.mapState.isGeolocationActive = true;
                const position = await this.getCurrentPosition();
                await this.updateMapPosition(position);
            } catch (error) {
                this.handleGeolocationError(error);
            } finally {
                this.mapState.isGeolocationActive = false;
            }
        },

        updateHomeStore(addressDetails) {
            const { street, house, city, country, fullText } = addressDetails;

            // Create complete address if we have street and house
            let address = '';
            if (street && house) {
                address = `${street}, ${house}`;
                this.homeStore.showSuggestions = false;
            } else {
                address = fullText;
            }

            // Update home store with address details
            this.homeStore.address = address;
            this.homeStore.updateAddressComponents({
                country,
                city,
                street,
                house,
                fullAddress: address,
                isComplete: !!house
            });
        },

        getCurrentPosition() {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject, {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                });
            });
        },

        async updateMapPosition(position) {
            const { latitude, longitude } = position.coords;
            const coords = [longitude, latitude];

            this.mapSettings.location.center = coords;
            this.mapSettings.location.zoom = this.MAP_CONFIG.DETAILED_ZOOM;

            await this.handleMapGeocoding(coords);
        },

        handleGeolocationError(error) {
            this.mapState.isGeolocationActive = false;

            const errorMessages = {
                1: "Доступ к геолокации запрещен",
                2: "Информация о местоположении недоступна",
                3: "Превышено время ожидания запроса геолокации"
            };

            console.error(errorMessages[error.code] || "Ошибка при определении местоположения");
        },

        resetMapToInitial() {
            setTimeout(() => {
                this.mapSettings.location.center = this.initialMapConfig.coords;
                this.mapSettings.location.zoom = this.initialMapConfig.zoom;
            }, this.TIMEOUTS.ZOOM_ANIMATION);
        }
    },

    async mounted() {
        try {
            const { coords, zoom } = this.initialMapConfig;
            this.mapSettings.location.center = coords;
            this.mapSettings.location.zoom = zoom;

            const region = this.clinicStore.clinicRegion;
            if (region) {
                await this.homeStore.fetchZonesAdd(region);
                this.polygons = this.homeStore.zonePolygonByRegion()
                    .map(polygon => ({
                        ...polygon,
                        coords: polygon.coords.map(coord => coord.map(c => c.reverse()))
                    }));
            }

            if (this.uiStore.debugMode) {
                this.updateDebugBounds();
            }

            this.showMap = true;
        } catch (error) {
            console.error("Map initialization error:", error);
        }
    },

    watch: {
        'mapEvents.behavior.drag'(isDragging) {
            this.mapState.isAddressLoading = true;
            this.mapState.isInteracting = isDragging;
            if (!isDragging && this.mapCenter) {
                clearTimeout(this.geocodeTimeout);
                this.geocodeTimeout = setTimeout(() => {
                    this.handleMapGeocoding(this.mapCenter);
                }, this.TIMEOUTS.ADDRESS_DETECTION);
            }
        },

        'homeStore.coords': {
            handler(newCoords) {
                if (!newCoords?.length) {
                    this.resetMapToInitial();
                    return;
                }

                const mappedCoords = newCoords.map(coord => +coord).reverse();
                setTimeout(() => {
                    this.mapSettings.location.zoom = this.MAP_CONFIG.DETAILED_ZOOM;
                    this.mapSettings.location.center = mappedCoords;
                }, this.TIMEOUTS.ZOOM_ANIMATION);
            },
            immediate: true
        },
        'uiStore.debugMode': {
            handler() {
                if (this.uiStore.debugMode) {
                    this.updateDebugBounds();
                }
            }
        }
    }
};
</script>

<style scoped>
.hint-window {
    position: absolute;
    transform: translate(7px, -100%);
    padding: 4px;
    background: white;
    border: 1px solid black;
    opacity: 0.7;
    white-space: nowrap;
}

.map-container button {
    transition: all 0.2s ease-in-out;
    min-width: 2.5rem; /* 40px */
    min-height: 2.5rem; /* 40px */
}

.map-container button:active {
    transform: scale(0.95);
}

.map-container button svg {
    margin: auto;
}
</style>
